import React, { useContext, useEffect, useState } from 'react';
import {
  transactionServices,
  useGetAccountInfo,
  useGetPendingTransactions
} from '@elrondnetwork/dapp-core';
import { Address, AddressValue } from '@elrondnetwork/erdjs/out';
import { environment } from 'App';
import BoostsList from 'components/Boosts/BoostsList';
import { SuccessModal } from 'components/Modal/Modals';
import { base64ToDecimal, base64ToHex } from 'components/TxTest/functions';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import {
  boostShopAdress,
  boostShopLuckAdress,
  pandaToken,
  gateway,
  boostShopSft15,
  boostShopSft30,
  boostShopSft40,
  boostShopSft50,
  boostShopSft,
  boostShopLuckSft15,
  boostShopLuckSft30,
  boostShopLuckSft40,
  boostShopLuckSft50,
  boostShopLuckSft
} from 'config';
import { PandaContext } from 'context/PandaContext';
import ticket15 from '../../../assets/img/boost/ticket-15.png';
import ticket30 from '../../../assets/img/boost/ticket-30.png';
import ticket40 from '../../../assets/img/boost/ticket-40.png';
import ticket50 from '../../../assets/img/boost/ticket-50.png';
import ticket15Luck from '../../../assets/img/boost/ticket-luck-15.png';
import ticket30Luck from '../../../assets/img/boost/ticket-luck-30.png';
import ticket40Luck from '../../../assets/img/boost/ticket-luck-40.png';
import ticket50Luck from '../../../assets/img/boost/ticket-luck-50.png';
import ticketsTime from './tickets.png';
import ticketsLuck from './ticketsLuck.png';

export default function Boostshop() {
  const abiShopUrl = '../../assets/json/random_item_shop.abi.json';
  const { address } = useGetAccountInfo();
  const { pendingTransactionsArray, hasPendingTransactions } =
    useGetPendingTransactions();

  const [hasToRefillTime, setHasToRefillTime] = useState();
  const [hasToRefillLuck, setHasToRefillLuck] = useState();
  const [txId, setTxId] = useState() as any;
  const [refresh, setRefresh] = useState(0);
  const [modalMessage, setModalMessage] = useState('');

  const { pdtValue, userBoosts, refreshUserBoosts, setRefreshUserBoosts } =
    useContext(PandaContext);

  const successfulTx: any = transactionServices.useGetSuccessfulTransactions();

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: txId,
    onSuccess: async () => {
      // On va chercher quell boost a été gagné par l'user
      const hashOfTheMint =
        successfulTx.successfulTransactions[txId].transactions[0].hash;
      const response = await fetch(gateway + 'transactions/' + hashOfTheMint);
      const json = await response.json();
      console.log(atob(json.logs.events[1].topics[0]));
      console.log(base64ToHex(json.logs.events[1].topics[1]));
      const boostWon =
        atob(json.logs.events[1].topics[0]) +
        '-' +
        base64ToHex(json.logs.events[1].topics[1]);
      //Switch to know which image the boost corresponds
      console.log(boostWon);

      switch (boostWon) {
        case boostShopSft15:
          setModalMessage(' <img src=' + ticket15 + '>');
          break;
        case boostShopSft30:
          setModalMessage('<img src=' + ticket30 + '>');
          break;
        case boostShopSft40:
          setModalMessage('' + ticket40 + '>');
          break;
        case boostShopSft50:
          setModalMessage('<img src=' + ticket50 + '>');
          break;
        case boostShopLuckSft15:
          setModalMessage('<img src=' + ticket15Luck + '>');
          break;
        case boostShopLuckSft30:
          setModalMessage('<img src=' + ticket30Luck + '>');
          break;
        case boostShopLuckSft40:
          setModalMessage('<img src=' + ticket40Luck + '>');
          break;
        case boostShopLuckSft50:
          setModalMessage('<img src=' + ticket50Luck + '>');
          break;
      }
      //On set le msg de la modal et refresh les stats de l'user

      setRefreshUserBoosts(refreshUserBoosts + 1);
      setRefresh(refresh + 1);
      //on ouvre la modal success
      const modalHtml: any = document.getElementById('successModal');
      modalHtml.checked = true;
    },
    onFail: () => {
      console.log('fail');
    },
    onCancelled: () => {
      console.log('cancel');
    }
  });

  useEffect(() => {
    if (hasPendingTransactions) {
      setTxId(pendingTransactionsArray[0][0]);
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    //hasToRefill Time
    querySc(boostShopAdress, 'hasToRefill', abiShopUrl, [
      new AddressValue(new Address(address))
    ]).then((res) => {
      setHasToRefillTime(res);
    });
  }, [refresh]);

  useEffect(() => {
    //hasToRefill Luck
    querySc(
      environment == 'devnet' ? boostShopAdress : boostShopLuckAdress,
      'hasToRefill',
      abiShopUrl,
      [new AddressValue(new Address(address))]
    ).then((res) => {
      setHasToRefillLuck(res);
    });
  }, [refresh]);

  return (
    <>
      <h2 className=' !text-4xl !mb-3 text-center !normal-case'>Boostshop</h2>
      {/* <p className='max-w-4xl m-auto mt-8 '>
        In this shop you have the opportunity to get <b>boosts</b>. <br />
        You can use the boosts in the future <b>staking farms</b> and save time!
        The more you earn the more you can stack and get rewards regularly!
        It&apos;s a virtuous circle that your pandas bring you when you let them
        generate money!
      </p> */}
      <div className='relative grid md:grid-cols-2 z-10'>
        <div className='md:pl-7 pt-10 md:pt-0 justify-self-center self-center'>
          <div className='text-center mb-10 sm:m-10'>
            <img
              src={ticketsTime}
              alt='tickets'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='text-center'>
            <b className='text-2xl'>Random Panda Boost Time</b>
          </div>
          <div className='flex text-center justify-center gap-x-5 mb-10 md:mb-0'>
            <div>
              <div className='text-center'>0.075 EGLD</div>
              <div className='text-center'>
                <button
                  className='ep-btn-core text-ep-slate bg-ep-green  mb-2 px-10'
                  onClick={async () => {
                    const buyBoost: any = await sendTx(
                      boostShopAdress,
                      'buy',
                      [],
                      0.075,
                      abiShopUrl,
                      [],
                      '',
                      hasToRefillTime ? 100000000 : 10000000
                    );
                    setTxId(buyBoost.sessionId);
                  }}
                >
                  BUY
                </button>
              </div>
            </div>
            <div>
              <div className='text-center'>250 Panda Tokens</div>
              <div className='text-center'>
                <button
                  className='ep-btn-core text-ep-slate mb-2 px-10'
                  onClick={async () => {
                    if (pdtValue >= 250 * Math.pow(10, 18)) {
                      const buyBoost: any = await sendTx(
                        boostShopAdress,
                        'buy',
                        [],
                        0,
                        abiShopUrl,
                        [],
                        address,
                        hasToRefillTime ? 100000000 : 10000000,
                        [pandaToken, environment == 'devnet' ? 1 : 250]
                      );
                      setTxId(buyBoost.sessionId);
                    } else {
                      alert('Not enough Panda Tokens');
                    }
                  }}
                >
                  BUY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='justify-self-start self-center sm:mt-10 bg-ep-grey3 p-5 rounded-xl max-w-2xl'>
          As indicated, there are
          <b> 4 levels of rarity</b>, the
          <span style={{ color: '#C47B38' }}> bronze</span> one will reduce the
          waiting time by <span style={{ color: '#C47B38' }}> 15%</span>, the
          <span style={{ color: '#C6C4C3' }}> silver</span> ticket will give you
          <span style={{ color: '#C6C4C3' }}> 30%</span>, the
          <span style={{ color: '#D5B664' }}> gold</span> ticket will reduce
          your time by <span style={{ color: '#D5B664' }}> 40%</span> and the
          rarest! The <span style={{ color: '#A0C6E8' }}> diamond</span> boost
          of <span style={{ color: '#A0C6E8' }}> 50%</span> !
        </div>
      </div>

      <div className='relative grid md:grid-cols-2 z-10 mt-7'>
        <div className='md:pl-7 pt-10 md:pt-0 justify-self-center self-center'>
          <div className='text-center mb-10 sm:m-10'>
            <img
              src={ticketsLuck}
              alt='tickets'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='text-center'>
            <b className='text-2xl'>Random Panda Boost Luck</b>
          </div>
          <div className='flex text-center justify-center gap-x-5 mb-10 md:mb-0'>
            <div>
              <div className='text-center'>0.075 EGLD</div>
              <div className='text-center'>
                <button
                  className='ep-btn-core text-ep-slate bg-ep-green  mb-2 px-10'
                  onClick={async () => {
                    const buyBoost: any = await sendTx(
                      boostShopLuckAdress,
                      'buy',
                      [],
                      0.075,
                      abiShopUrl,
                      [],
                      '',
                      hasToRefillLuck ? 100000000 : 10000000
                    );
                    setTxId(buyBoost.sessionId);
                  }}
                >
                  BUY
                </button>
              </div>
            </div>
            <div>
              <div className='text-center'>250 Panda Tokens</div>
              <div className='text-center'>
                <button
                  className='ep-btn-core text-ep-slate  mb-2 px-10'
                  onClick={async () => {
                    if (pdtValue >= 250 * Math.pow(10, 18)) {
                      const buyBoost: any = await sendTx(
                        boostShopLuckAdress,
                        'buy',
                        [],
                        0,
                        abiShopUrl,
                        [],
                        address,
                        hasToRefillLuck ? 100000000 : 10000000,
                        [pandaToken, environment == 'devnet' ? 1 : 250]
                      );
                      setTxId(buyBoost.sessionId);
                    } else {
                      alert('Not enough Panda Tokens');
                    }
                  }}
                >
                  BUY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='justify-self-start self-center sm:mt-10 bg-ep-grey3 p-5 rounded-xl max-w-2xl'>
          As indicated, there are also
          <b> 4 levels of rarity</b>, the
          <span style={{ color: '#C47B38' }}> bronze</span> one will increase
          your luck of having a baby by{' '}
          <span style={{ color: '#C47B38' }}> 15%</span>, the
          <span style={{ color: '#C6C4C3' }}> silver</span> ticket will give you
          <span style={{ color: '#C6C4C3' }}> 30%</span>, the
          <span style={{ color: '#D5B664' }}> gold</span> ticket will reduce
          your time by <span style={{ color: '#D5B664' }}> 40%</span> and the
          rarest! The <span style={{ color: '#A0C6E8' }}> diamond</span> boost
          of <span style={{ color: '#A0C6E8' }}> 50%</span> !
        </div>
      </div>

      <h2 className=' !text-4xl !mb-3 text-center !normal-case'>Your boosts</h2>
      <BoostsList />
      <SuccessModal
        title={'Congratulation, you won this boost'}
        message={modalMessage}
        buttonText={'Yay !'}
      />
    </>
  );
}
