import React from 'react';
import { useRef } from 'react';
import {
  BigUIntValue,
  Balance,
  Token,
  U8Value
} from '@elrondnetwork/erdjs/out';
import { sendTx } from 'components/TxTest/TxFunctions';

export default function SetBuyableSft(props: any) {
  const abiUrl = props.abi;
  const boostShopAdress = props.sc;

  const weightRef: any = useRef();
  return (
    <tr key={props.index}>
      <td>{props.sft.identifier}</td>
      <td>{props.sft.name}</td>
      <td>
        {props.sft.buyable ? (
          props.sft.weight
        ) : (
          <input
            ref={weightRef}
            type='number'
            name='setBuyableSftWeight'
            placeholder='Weight'
          />
        )}
      </td>
      <td
        className={`${props.sft.buyable ? 'text-green-500' : 'text-red-500'}`}
      >
        {props.sft.buyable ? 'Buyable' : 'Non buyable'}
      </td>
      <td>
        {props.sft.buyable ? (
          <button
            onClick={() => {
              sendTx(
                boostShopAdress,
                'removeBuyableSft',
                [
                  new BigUIntValue(
                    new Balance(new Token(), 0, props.sft.nonce).valueOf()
                  )
                ],
                0,
                abiUrl
              );
            }}
            className='ep-btn'
          >
            Remove buyable
          </button>
        ) : (
          <button
            onClick={() => {
              sendTx(
                boostShopAdress,
                'setBuyableSft',
                [
                  new BigUIntValue(
                    new Balance(new Token(), 0, props.sft.nonce).valueOf()
                  ),
                  new U8Value(weightRef.current.value)
                ],
                0,
                abiUrl
              );
            }}
            className='ep-btn'
          >
            Set Buyable
          </button>
        )}
      </td>
    </tr>
  );
}
