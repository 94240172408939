import { React, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WpMenuSingle from './WpMenuSingle/WpMenuSingle';
import './WpMenu.css';
import { NavLink } from 'react-router-dom';

export default function WpMenu(props) {
  const menuArray = props.menu;
  const [toggleMenu, setToggleMenu] = useState(false);
  const closeMenu = () => {
    setToggleMenu(false);
  };

  //On trie le menu par ordre
  menuArray.sort((a, b) => a.acf.order - b.acf.order);
  return (
    <>
      <div
        className={`ep-wp-menu-left absolute right-full md:right-0 ${
          toggleMenu && 'right-0'
        } h-full w-full pb-20 md:relative md:basis-72 pl-8 py-6 overflow-auto bg-ep-blue`}
      >
        <div className='text-center mb-5'>
          <NavLink
            to={process.env.PUBLIC_URL + '/whitepaper/en/'}
            className={`cursor-pointer text-ep-slate leading-10 mx-2 px-2 py-2 text-sm sm:text-base font-medium border border-white hover:bg-gray-100 hover:text-black ${
              props.lang == 'en' &&
              ' bg-gray-100 font-semi-bold !border-gray-300 text-black '
            }`}
          >
            EN
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + '/whitepaper/fr/'}
            className={`cursor-pointer text-ep-slate leading-10 mx-2 px-2 py-2 text-sm sm:text-base font-medium border border-white hover:bg-gray-100 hover:text-black  ${
              props.lang == 'fr' &&
              'bg-gray-100 font-semi-bold !border-gray-300 text-black '
            }`}
          >
            FR
          </NavLink>
          {/* <NavLink
              to={process.env.PUBLIC_URL + '/ro/'}
              className={`cursor-pointer text-black leading-10 mx-2 px-2 py-2 text-sm sm:text-base font-medium border border-white hover:bg-gray-100 ${
                props.lang == 'ro' &&
                'bg-gray-100 font-semi-bold !border-gray-300'
              }`}
            >
              RO
            </NavLink> */}
        </div>
        {menuArray &&
          menuArray.map((item) => {
            return (
              <div className='flex flex-col' key={uuidv4()}>
                <div className='font-semibold leading-10 text-slate-400 uppercase text-sm'>
                  {item.name}
                </div>
                <WpMenuSingle
                  func={closeMenu}
                  id={item.id}
                  whitepaper={props.whitepaper}
                  lang={props.lang}
                />
              </div>
            );
          })}
        <div
          onClick={() => setToggleMenu(!toggleMenu)}
          className={`fixed right-1/2 bottom-6 translate-x-2/4 border border-r rounded px-8	py-1 md:hidden cursor-pointer drop-shadow-md active:translate-y-px ${
            toggleMenu
              ? 'bg-ep-slate text-black hover:bg-slate-200'
              : 'bg-ep-blue text-ep-slate hover:bg-blue-900'
          }`}
        >
          {toggleMenu ? 'Close' : 'Menu'}
        </div>
      </div>
    </>
  );
}
