import React, { useEffect } from 'react';
import { getIsLoggedIn, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { useNavigate, useParams } from 'react-router-dom';
import DashBoardMenu from 'components/DashBoard/DashBoardMenu';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import Boostshop from './Boostshop/Boostshop';
import DashboardPanda from './DashboardPanda';
import footprint from './footprint_noir.png';
import Mint from './Mint/Mint';
import News from './News/News';
import Stack from './Stack/Stack';

export default function DashBoardPanda2() {
  const params = useParams();
  const { address, account } = useGetAccountInfo();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(getIsLoggedIn());

    if (address === '') navigate('/unlock'); //Si user pas connecté
  });

  return (
    <div className='relative'>
      <DashBoardMenu />

      <div className='md:ml-7'>
        <div className='ep-container text-ep-slate'>
          <NavbarPanda />
        </div>
        <div className='ep-dashboard bg-ep-blue text-ep-slate'>
          <div className='ep-container h-full mb-10 md:mb-0'>
            {params.page === undefined && <DashboardPanda />}
            {params.page === 'profile' && <DashboardPanda />}
            {params.page === 'stack' && <Stack />}
            {params.page === 'stake' && <Stack />}
            {params.page === 'news' && <News />}
            {params.page === 'boostshop' && <Boostshop />}
            {params.page === 'vote' && <p>A venir</p>}
            {params.page === 'mint' && <Mint />}
          </div>
        </div>
      </div>
      <img
        className='dashboard-fp dashboard-fp-1'
        src={footprint}
        alt='Panda footprint'
      />
      <img
        className='dashboard-fp dashboard-fp-2'
        src={footprint}
        alt='Panda footprint'
      />
      <img
        className='dashboard-fp dashboard-fp-3'
        src={footprint}
        alt='Panda footprint'
      />
      <img
        className='dashboard-fp dashboard-fp-4'
        src={footprint}
        alt='Panda footprint'
      />
      <img
        className='dashboard-fp dashboard-fp-5'
        src={footprint}
        alt='Panda footprint'
      />
    </div>
  );
}
