import { React, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './WpMenuSingle.css';

export default function WpMenuSingle(props) {
  let toggle = true;

  const [firstPage, setFirstPage] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => { //A continuer, essayer d'avoir item slug en dehors de la fonction
  //   if (firstPage) {
  //     navigate(process.env.PUBLIC_URL + `/${props.lang}/${item.slug}`)
  //     setFirstPage(false)
  //   }
  // }, [])

  useEffect(() => {
    const epWpCore = document.getElementsByClassName('ep-wp-core');
    epWpCore[0].scroll(0, 0);
  }, []);

  return props.whitepaper.map((item) => {
    let menuLang = 'menu-' + props.lang;
    if (item[menuLang][0] === props.id) {
      return (
        <NavLink
          onClick={() => {
            props.func();
          }}
          key={uuidv4()}
          to={process.env.PUBLIC_URL + `/whitepaper/${props.lang}/${item.slug}`}
          className='wp-menu-single border border-transparent pr-3 pl-6 cursor-pointer leading-10 text-ep-slate	font-medium text-sm hover:bg-sky-900		lowercase first-letter:capitalize'
        >
          {item.title.rendered}
        </NavLink>
      );
    }
  });
}
