import React from 'react';

export default function ContactIcon(props) {
  return (
    <a
      className='justify-self-center self-center'
      href={props.link}
      target='_blank'
      rel='noreferrer'
    >
      <img
        className='w-20 hover:scale-110'
        src={props.image}
        alt='Discord Icon'
      />
    </a>
  );
}
