import React from 'react';
import './Unlock.css';
import { DappUI, useGetLoginInfo } from '@elrondnetwork/dapp-core';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import SocialLink from 'components/SocialLink/SocialLink';
import { routeNames } from 'routes';
import footprint from './footprint_noir.png';

export default function UnlockPanda() {
  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.dashboard;
    }
  }, [isLoggedIn]);

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='ep-connexion flex justify-center relative'>
        <div className='self-center text-center mb-20'>
          <h1 className='text-5xl font-bold text-ep-slate md:text-7xl mb-5'>
            CONNEXION
          </h1>
          <div className='flex gap-y-4 flex-wrap justify-center w-52 m-auto flex-col md:flex-row md:gap-x-4 md:w-full md:px-10 md:flex-nowrap'>
            <ExtensionLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Extension'}
              className=' '
            />
            <WebWalletLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Web wallet'}
              className=' '
            />
            <LedgerLoginButton
              loginButtonText={'Ledger'}
              callbackRoute={routeNames.dashboard}
              className=' '
            />
            <WalletConnectLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Maiar'}
              className=''
            />
          </div>
        </div>
        <img
          className='absolute ep-connexion-fp-1 z-0'
          src={footprint}
          alt='Panda footprint'
        />
        <img
          className='absolute ep-connexion-fp-2 hidden z-0'
          src={footprint}
          alt='Panda footprint'
        />
        <img
          className='absolute ep-connexion-fp-3 z-0'
          src={footprint}
          alt='Panda footprint'
        />
        <SocialLink />
      </div>
    </>
  );
}
