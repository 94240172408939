import React, { useContext, useEffect, useState } from 'react';
import {
  logarithmicRest,
  transactionServices,
  useGetAccountInfo,
  useGetPendingTransactions
} from '@elrondnetwork/dapp-core';
import { Address, AddressValue, U32Value } from '@elrondnetwork/erdjs/out';
import { v4 as uuidv4 } from 'uuid';
import FertilityCard from 'components/Card/FertilityCard';
import {
  BoostChoiceModal,
  ErrorModal,
  SuccessModal
} from 'components/Modal/Modals';
import { base64ToDecimal } from 'components/TxTest/functions';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import {
  gateway,
  pandaCollection2 as pandaCollection,
  babyStakingAddress
} from 'config';
import { PandaContext } from 'context/PandaContext';
import CoupleStaked from './CoupleStaked';
import './babyPandas.css';

export default function BabyPandas() {
  const { address, account } = useGetAccountInfo();
  const [userFemalePandas, setUserFemalePandas] = useState() as any;
  const [userMalePandas, setUserMalePandas] = useState() as any;
  const [femaleChoosed, setFemaleChoosed] = useState(0);
  const [maleChoosed, setMaleChoosed] = useState(0);
  const [coupleStaked, setCoupleStaked] = useState([]) as Array<any>;
  const abiUrl = '/assets/json/baby_staking.json';
  const [boostModalShow, setBoostModalShow] = useState(false);
  const [coupleIdToBoost, setCoupleIdToBoost] = useState();
  const [txId, setTxID] = useState(null) as any;
  const [txType, setTxType] = useState(null) as any; //définit le type de tx pour pouvoir afficher la bonne modal
  const { refreshUserBoosts, setRefreshUserBoosts } = useContext(PandaContext);
  const [refresh, setRefresh] = useState(0);
  const [successModalTitle, setSuccessModalTitle] = useState('');
  const { pendingTransactionsArray, hasPendingTransactions } =
    useGetPendingTransactions();
  const successfulTx: any = transactionServices.useGetSuccessfulTransactions();
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [successModalText, setSuccessModalText] = useState('');
  const [successModalBtn, setSuccessModalBtn] = useState('');
  const [babyReceived, setBabyReceived] = useState(0);

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: txId,
    onSuccess: async () => {
      //Va chercher le panda minté si un tx est successfull
      const hashOfTheMint =
        successfulTx.successfulTransactions[txId].transactions[0].hash;

      try {
        //on va chercher la transaction sur l'api
        let json: any = '';
        setBabyReceived(0); //On reset le baby received pour ne pas qu'il s'affiche sur la modal
        if (!txType || txType === 'claimBaby') {
          //on envoit une tx que si nécessaire
          const response = await fetch(
            gateway + 'transactions/' + hashOfTheMint
          );
          // console.log(gateway + 'transactions/' + hashOfTheMint);
          json = await response.json();
        }

        // console.log('json', json);
        // console.log(txType);

        if (txType === 'stakeCouple' || json.function === 'stakeCouple') {
          setSuccessModalTitle('Congratulation');
          setSuccessModalText('Your pandas are now in couple !');
          setSuccessModalBtn('Yay!');
          setRefresh(refresh + 1);
        } else if (
          txType === 'boost' ||
          json.function === 'addDurationBoost' ||
          json.function === 'addDropBoost'
        ) {
          setSuccessModalTitle(
            'Congratulations, you have boosted your couple!'
          );
          setSuccessModalText('');
          setSuccessModalBtn('Yay!');
          setRefresh(refresh + 1);
          setRefreshUserBoosts(refreshUserBoosts + 1);
        } else if (json.function === 'claimIfBaby') {
          if (json.logs.events[0].identifier === 'ESDTNFTTransfer') {
            setSuccessModalTitle(
              'Congratulations, your couple had a baby panda together!'
            );
            const babyPandaId: number = base64ToDecimal(
              json.logs.events[0].topics[1]
            );
            setSuccessModalText('');
            setSuccessModalBtn('Yay!');
            setBabyReceived(babyPandaId);
            setRefresh(refresh + 1);
          } else {
            // eslint-disable-next-line quotes
            setSuccessModalTitle("Sorry, that didn't work...");
            setSuccessModalText(
              // eslint-disable-next-line quotes
              "Maybe you'll be more lucky next time. Try with a <b>boost</b> !"
            );
            setSuccessModalBtn('Sure');
            setRefresh(refresh + 1);
          }
        } else if (json.function === 'retrieveNfts' || txType === 'unstake') {
          setSuccessModalTitle('Success !');
          setSuccessModalText('Your pandas are back home');
          setSuccessModalBtn('Yay!');
          setRefresh(refresh + 1);
        }
        const modalHtml: any = document.getElementById('successModal');
        modalHtml.checked = true;
      } catch (error) {
        console.log(error);
      }
    },
    onFail: () => {
      console.log('fail');
    },
    onCancelled: () => {
      console.log('cancel');
    }
  });

  const setMale = (id: number) => {
    id === maleChoosed ? setMaleChoosed(0) : setMaleChoosed(id);
  };

  const setFemale = (id: number) => {
    id === femaleChoosed ? setFemaleChoosed(0) : setFemaleChoosed(id);
  };

  useEffect(() => {
    if (hasPendingTransactions) {
      setTxID(pendingTransactionsArray[0][0]);
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    //Elrond Pandas
    const userPandasLink =
      gateway +
      'accounts/' +
      address +
      '/nfts?size=10000&collections=' +
      pandaCollection;

    let apiError = 0;

    const userPandasFetchApi = () => {
      fetch(userPandasLink)
        .then((response) => {
          const userPandasJson = response.json();
          return userPandasJson;
        })
        .then((userPandasJson) => {
          // console.log(userPandasLink);

          const newMales: any = [];
          const newFemales: any = [];
          userPandasJson.map((panda: any) => {
            panda.id = uuidv4();
            if (panda.metadata.attributes[9].value === 'Man') {
              newMales.push(panda);
            } else {
              newFemales.push(panda);
            }
          });
          setUserMalePandas(newMales);
          setUserFemalePandas(newFemales);
        })
        .catch(function (error) {
          apiError++;
          if (apiError < 3) {
            userPandasFetchApi();
          } else {
            //On affiche le modal
            setErrorModalTitle('Something went wrong');
            setErrorModalMessage(
              'Please refresh page or contact an administrator if the problem persists'
            );
            const element: any = document.getElementById('errorModal');
            element.checked = true;
          }
        });
    };

    userPandasFetchApi();
  }, [refresh]);

  useEffect(() => {
    let apiError = 0;

    //getUsers
    const fetchUserData = async () => {
      const queryUser: any = await querySc(
        babyStakingAddress,
        'getUsers',
        abiUrl,
        [new AddressValue(new Address(address))]
      )
        .then((res: any) => {
          res.map((user: any) => {
            user.id = uuidv4();
          });
          setCoupleStaked(res);
        })
        .catch((err) => {
          apiError++;
          if (apiError < 3) {
            fetchUserData();
          } else {
            //On affiche le modal
            setErrorModalTitle('Something went wrong');
            setErrorModalMessage(
              'Please refresh page or contact an administrator if the problem persists'
            );
            const element: any = document.getElementById('errorModal');
            element.checked = true;
          }
        });
    };
    fetchUserData();
  }, [refresh]);

  const boostModalShowToggle = () => {
    setBoostModalShow(!boostModalShow);
  };

  return (
    <>
      <div className='relative md:flex md:gap-x-8 z-10'>
        <div className='md:pl-7 pt-10 md:pt-0 justify-self-start self-start grow shrink-0'>
          <div className='grid sm:grid-cols-2 '>
            <div className='maleCol mb-10 md:mb-0'>
              <div className='text-center font-bold text-xl mb-1'>
                Select a male
              </div>
              <div className='px-5 max-h-60fert500  overflow-y-scroll sm:w-64 overscroll-contain ep-scrollbar'>
                {userMalePandas
                  ? userMalePandas.length
                    ? userMalePandas.map((panda: any) => {
                        return (
                          <FertilityCard
                            key={panda.id}
                            panda={panda}
                            link={'ElrondPandas'}
                            nftName={'Elrond Panda'}
                            collection={'ElrondPandas'}
                            extension='png'
                            setPanda={setMale}
                            selected={
                              maleChoosed === panda.nonce ? true : false
                            }
                          />
                        );
                      })
                    : // eslint-disable-next-line quotes
                      "You don't have any male panda in your wallet. If your pandas are already staked, unstake them to access this pool."
                  : ''}
              </div>
            </div>
            <div className='femaleCol'>
              <div className='text-center font-bold text-xl mb-1'>
                Select a female
              </div>
              <div className='px-5 max-h-60fert500 overflow-y-scroll sm:w-64 overscroll-contain	ep-scrollbar'>
                {userFemalePandas
                  ? userFemalePandas.length
                    ? userFemalePandas.map((panda: any) => {
                        return (
                          <FertilityCard
                            key={panda.id}
                            panda={panda}
                            link={'ElrondPandas'}
                            nftName={'Elrond Panda'}
                            collection={'ElrondPandas'}
                            extension='png'
                            setPanda={setFemale}
                            selected={
                              femaleChoosed === panda.nonce ? true : false
                            }
                          />
                        );
                      })
                    : // eslint-disable-next-line quotes
                      "You don't have any female panda in your wallet. If your pandas are already staked, unstake them to access this pool."
                  : ''}
              </div>
            </div>
          </div>
          <div className='text-center mt-3 mb-10 md:mb-0'>
            <button
              className={`ep-btn-core text-ep-slate  mb-2 px-10 ${
                femaleChoosed && maleChoosed
                  ? 'bg-ep-green hover:bg-ep-green2 cursor-pointer'
                  : '!bg-gray-600 cursor-not-allowed border-gray-600'
              }`}
              onClick={() => {
                if (maleChoosed && femaleChoosed) {
                  const coupleStakingTx: any = sendTx(
                    babyStakingAddress,
                    'stakeCouple',
                    [],
                    0,
                    abiUrl,
                    [
                      [pandaCollection, maleChoosed],
                      [pandaCollection, femaleChoosed]
                    ],
                    address
                  );
                  setTxType('stakeCouple');
                  setTxID(coupleStakingTx.sessionId);
                }
              }}
            >
              Stake
            </button>
          </div>
        </div>
        <div className='justify-self-center self-center grow-0	shrink'>
          Welcome to the first <b> Couple pandas pool</b>, <br />
          <br />
          In this farm you will be able to get babies after 30 days of staking.
          Those babies are represtented in the form of a NFT collection that
          will be earnable only via the use of this farm! <br />
          <br />
          No baby pandas will be sold by the project. And be careful, making
          babies tires the pandas... 🐼 <br />
          <br />
          It is imperative to put a panda pixel male and a female in it!
          Moreover pay attention to their statistics, their fertility and their
          energy is taken into account! The use of our boosts are possible in
          this farm to gain speed or luck to have a baby.
          <br />
          <br />
          These babies will allow you to get families and lots of other
          benefits! <br />
        </div>
      </div>
      {coupleStaked && coupleStaked.length > 0 && (
        <div className='md:pl-7 pt-10 md:pt-0'>
          <h2 className='!text-4xl !mb-10 text-center !normal-case'>
            Couple{coupleStaked.length > 1 && 's'} stacked
          </h2>
          <div className='pl-5'>
            {coupleStaked.map((couple: any, index: number) => {
              return (
                <CoupleStaked
                  key={couple.id}
                  coupleIndex={index}
                  coupleInfo={couple}
                  boostModalShowToggle={boostModalShowToggle}
                  setCoupleIdToBoost={setCoupleIdToBoost}
                  scAddress={babyStakingAddress}
                  userAddress={address}
                  abiUrl={abiUrl}
                  setTxType={setTxType}
                  setTxID={setTxID}
                />
              );
            })}
          </div>
        </div>
      )}
      <BoostChoiceModal
        show={boostModalShow}
        boostModalShowToggle={boostModalShowToggle}
        coupleIdToBoost={coupleIdToBoost}
        abiUrl={abiUrl}
        userAddress={address}
        setTxType={setTxType}
        setTxID={setTxID}
      />
      <SuccessModal
        title={successModalTitle}
        message={successModalText}
        babyPandaId={babyReceived}
        buttonText={successModalBtn}
      />
      <ErrorModal title={errorModalTitle} message={errorModalMessage} />
    </>
  );
}
