import { promises } from 'fs';
import React, { useEffect } from 'react';
import { sendTransactions } from '@elrondnetwork/dapp-core';
import { DappProvider } from '@elrondnetwork/dapp-core';
import {
  Address,
  AddressValue,
  ContractFunction,
  ProxyProvider,
  Query,
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Interaction,
  GasLimit,
  BooleanValue,
  Nonce,
  NetworkConfig,
  ArgSerializer,
  ContractInterface,
  Balance,
  Token,
  TypedValue,
  Transaction,
  QueryResponse,
  createBalanceBuilder,
  TokenIdentifierValue,
  TokenType,
  BigUIntValue
} from '@elrondnetwork/erdjs';
import { ProxyNetworkProvider } from '@elrondnetwork/erdjs-network-providers';
import axios, { AxiosResponse } from 'axios';
import { environment as env } from 'App';
import { gatewayUnslashed as gateway } from 'config';

export async function sendTx(
  scAdress: string,
  txMethod: string,
  txArgs: any,
  txValue: number,
  abiUrl: string,
  nft: Array<any> = [],
  sender = '',
  gasLimit = 0,
  esdt: Array<any> = []
) {
  console.log(
    'sendTx',
    scAdress,
    txMethod,
    txArgs,
    txValue,
    abiUrl,
    nft,
    sender,
    gasLimit,
    esdt
  );
  const networkProvider = new ProxyProvider(gateway);
  await NetworkConfig.getDefault().sync(networkProvider);
  // console.log(NetworkConfig.getDefault().ChainID);

  const abi = await SmartContractAbi.fromAbiUrl(abiUrl);

  const contract = new SmartContract({
    address: new Address(scAdress),
    abi: abi
  });

  const txInteraction: Interaction = contract.methods[txMethod](txArgs);

  if (nft.length) {
    // console.log(nft.length);
    //On rajoute le nft
    if (sender == '') return console.log('No sender');

    if (nft.length === 1) {
      //Single nft transfer
      const TokenFoo = createBalanceBuilder(
        new Token({
          identifier: nft[0][0],
          decimals: 0,
          type: TokenType.Fungible
        })
      );

      txInteraction.withSingleESDTNFTTransfer(
        TokenFoo.nonce(nft[0][1]).one(),
        new Address(sender)
      );
    } else {
      //Multi nft transfer
      const TokenFooBalance = [];

      for (let i = 0; i < nft.length; i++) {
        TokenFooBalance.push(
          createBalanceBuilder(
            new Token({
              identifier: nft[i][0],
              decimals: 0,
              type: TokenType.Fungible
            })
          )
            .nonce(nft[i][1])
            .one()
        );
      }
      txInteraction.withMultiESDTNFTTransfer(
        TokenFooBalance,
        new Address(sender)
      );
    }
  }

  if (esdt.length) {
    //On rajoute l'esdt

    const TokenFoo = createBalanceBuilder(
      new Token({
        identifier: esdt[0],
        decimals: 18,
        type: TokenType.Fungible
      })
    );

    txInteraction.withSingleESDTTransfer(TokenFoo.value(esdt[1]));
  }

  const transaction = txInteraction.buildTransaction();

  if (gasLimit != 0) {
    transaction.setGasLimit(new GasLimit(gasLimit));
  } else {
    if (txMethod == 'stake') {
      transaction.setGasLimit(new GasLimit(7000000));
    } else if (txMethod == 'claimThenUnstake') {
      transaction.setGasLimit(new GasLimit(7000000));
    } else if (txMethod == 'claimAll') {
      transaction.setGasLimit(new GasLimit(40000000));
    } else {
      transaction.setGasLimit(new GasLimit(50000000));
    }
  }

  if (txValue) transaction.setValue(Balance.egld(txValue));

  const t = {
    transactions: transaction,
    transactionsDisplayInfo: {}
  };

  // console.log(transaction);

  const txResponse = await sendTransactions(t);
  return txResponse;
  // console.log(txResponse);
}

export async function querySc(
  scAdress: string,
  queryMethod: string,
  abiUrl: string,
  txArgs: any = ''
) {
  // console.log(txArgs);
  // console.log(queryMethod);

  const networkProvider = new ProxyProvider(gateway);

  // await NetworkConfig.getDefault().sync(networkProvider);
  const abi = await SmartContractAbi.fromAbiUrl(abiUrl);
  const contract = new SmartContract({
    address: new Address(scAdress),
    abi: abi
  });

  const activeInteraction: Interaction = contract.methods[queryMethod](txArgs);

  // Pour les executions de query (=pas besoin de signature):
  const queryResponse = await networkProvider.queryContract(
    activeInteraction.buildQuery()
  );

  if (queryResponse.isSuccess()) {
    const interpretedQuery =
      activeInteraction.interpretQueryResponse(queryResponse);

    // le résultat:
    // console.log(interpretedQuery);

    return interpretedQuery.firstValue.valueOf();
  } else {
    return 'error: ' + queryResponse.getReturnMessage();
  }
}

export default {};
