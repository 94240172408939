import React, { useContext } from 'react';
import { PandaContext } from 'context/PandaContext';
import ticket15 from '../../assets/img/boost/ticket-15.png';
import ticket30 from '../../assets/img/boost/ticket-30.png';
import ticket40 from '../../assets/img/boost/ticket-40.png';
import ticket50 from '../../assets/img/boost/ticket-50.png';
import ticket15Luck from '../../assets/img/boost/ticket-luck-15.png';
import ticket30Luck from '../../assets/img/boost/ticket-luck-30.png';
import ticket40Luck from '../../assets/img/boost/ticket-luck-40.png';
import ticket50Luck from '../../assets/img/boost/ticket-luck-50.png';

export default function BoostsList() {
  const boostShopFlexItemClass =
    'flex flex-wrap justify-center mt-4 md:flex-nowrap md:justify-start';

  const { pdtValue, userBoosts, refreshUserBoosts, setRefreshUserBoosts } =
    useContext(PandaContext);

  return (
    <div className='grid md:grid-cols-2 gap-4 pt-10 md:ml-28'>
      <div>
        <h3 className='!text-3xl !mb-6 text-center !normal-case'>
          Boost of time
        </h3>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket50}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostTime50}
          </div>
        </div>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket40}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostTime40}
          </div>
        </div>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket30}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostTime30}
          </div>
        </div>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket15}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostTime15}
          </div>
        </div>
      </div>
      <div>
        <h3 className='!text-3xl !mb-6 mt-6 text-center !normal-case md:mt-0'>
          Boost of luck
        </h3>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket50Luck}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostLuck50}
          </div>
        </div>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket40Luck}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostLuck40}
          </div>
        </div>
        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket30Luck}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostLuck30}
          </div>
        </div>

        <div className={boostShopFlexItemClass}>
          <div className='text-center justify-self-end'>
            <img
              src={ticket15Luck}
              alt='ticket'
              className='w-full m-auto sm:w-9/12 md:max-w-md'
            />
          </div>
          <div className='justify-self-center md:justify-self-start self-center text-4xl font-bold md:ml-10'>
            {userBoosts.boostLuck15}
          </div>
        </div>
      </div>
    </div>
  );
}
