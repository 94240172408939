import React from 'react';
import ContactIcon from 'pages/Index/ContactIcon/ContactIcon';
import DiscordIcon from '../../../../../src/assets/img/Discord_icon.png';
import TwitterIcon from '../../../../../src/assets/img/twitter_icon.png';

export default function Nsc1dText() {
  return (
    <div>
      <h3 className=' !font-semibold !text-xl !mb-3 !normal-case inline-block'>
        Learn more about our partner
      </h3>
      <br />
      <a href='https://elrond.nft-social.club/fr/accueil'>NFT Social Club</a> is
      the first contributor to win📄💸! <br />
      This platform allows everyone to view and add information about MultiversX
      (Elrond) projects. By contributing, you can earn rewards🎁. <br />
      Join them! <br />
      <div className='contactIcons'>
        <a
          href='https://elrond.nft-social.club/fr/accueil'
          target='_blank'
          rel='noreferrer'
        >
          <button className='ep-btn-core text-ep-slate bg-ep-green mb-2'>
            Website
          </button>
        </a>
        <ContactIcon
          link='https://discord.com/invite/dJWujrpaeY'
          image={DiscordIcon}
          alt='Discord Icon'
        />
        <ContactIcon
          link='https://twitter.com/NFT_Social_Club'
          image={TwitterIcon}
          alt='Twitter Icon'
        />
      </div>
    </div>
  );
}
