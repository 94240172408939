import React, { useEffect, useState, useRef } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
  Address,
  AddressValue,
  Balance,
  BigUIntValue,
  Token,
  TokenIdentifierValue
} from '@elrondnetwork/erdjs/out';
import { useNavigate } from 'react-router-dom';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import { babyStakingAddress } from 'config';

export default function BabyStaking() {
  const abiUrl = 'assets/json/baby_staking.json';
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();
  const [isSCactivated, setIsSCactivated] = useState(false);
  const [durationBoostStats, setDurationBoostStats] = useState();
  const [dropBoostStats, setDropBoostStats] = useState();
  const [stakingParams, setStakingParams] = useState() as any;
  const [totalBabiesSent, setTotalBabiesSent] = useState(0);
  const setAdminAddress = useRef(null) as any;
  const opt_drop_rate = useRef(null) as any;
  const opt_staking_duration = useRef(null) as any;
  const opt_allowed_couple_number = useRef(null) as any;
  const setDurationBoost = useRef(null) as any;
  const setDropBoost = useRef(null) as any;

  // useEffect(() => {
  //   //est-qyue l'utilisateur est admin
  //   const isAdmin = async () => {
  //     const queryIsAdmin: any = await querySc(
  //       babyStakingAddress,
  //       'isAdmin',
  //       abiUrl,
  //       [new AddressValue(new Address(address))]
  //     );
  //     if (typeof queryIsAdmin === 'boolean') {
  //       // !queryIsAdmin && navigate('/dashboard');
  //     } else {
  //       console.log(queryIsAdmin);
  //     }
  //   };
  //   isAdmin();
  // }, []);

  useEffect(() => {
    //Est-ce que le sc de claim est activé ?
    const fetchSCSTate = async () => {
      const queryBSstate: any = await querySc(
        babyStakingAddress,
        'getIsActive',
        abiUrl
      );
      if (typeof queryBSstate === 'boolean') {
        setIsSCactivated(queryBSstate);
        console.log(queryBSstate);
      } else {
        console.log(queryBSstate);
      }
    };
    fetchSCSTate();
  }, []);

  useEffect(() => {
    //getDurationBoostStats
    const fetchDurationBoostStats = async () => {
      const queryDurationBoostStats: any = await querySc(
        babyStakingAddress,
        'getDurationBoostStats',
        abiUrl
      );
      if (typeof queryDurationBoostStats === 'object') {
        console.log(queryDurationBoostStats);
      } else {
        console.log(queryDurationBoostStats);
      }
    };
    fetchDurationBoostStats();
  }, []);

  useEffect(() => {
    //getDropBoostStats
    const fetchDropBoostStats = async () => {
      const queryDropBoostStats: any = await querySc(
        babyStakingAddress,
        'getDropBoostStats',
        abiUrl
      );
      if (typeof queryDropBoostStats === 'object') {
        console.log(queryDropBoostStats);
      } else {
        console.log(queryDropBoostStats);
      }
    };
    fetchDropBoostStats();
  }, []);

  useEffect(() => {
    //getStakingParams
    const fetchStakingParams = async () => {
      const queryStakingParams: any = await querySc(
        babyStakingAddress,
        'getStakingParams',
        abiUrl
      );
      if (typeof queryStakingParams === 'object') {
        console.log(queryStakingParams);
        setStakingParams(queryStakingParams);
      } else {
        console.log(queryStakingParams);
      }
    };
    fetchStakingParams();
  }, []);

  useEffect(() => {
    //getTotalBabiesSent
    const fetchTotalBabiesSent = async () => {
      const queryTotalBabiesSent: any = await querySc(
        babyStakingAddress,
        'getTotalBabiesSent',
        abiUrl
      );
      if (typeof queryTotalBabiesSent === 'object') {
        console.log(queryTotalBabiesSent.toNumber());
        setTotalBabiesSent(queryTotalBabiesSent.toNumber());
      } else {
        console.log(queryTotalBabiesSent);
      }
    };
    fetchTotalBabiesSent();
  }, []);

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='ep-claim bg-ep-blue text-ep-slate pb-10'>
        <div className='ep-container h-full'>
          <h1 className='text-5xl font-bold text-center my-5'>
            Baby Staking - Administration
          </h1>

          {true && (
            <div>
              <h3 className='text-2xl underline mb-3'>
                Activation / Désaction
              </h3>
              <span
                className={`${
                  isSCactivated ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {isSCactivated && isSCactivated
                  ? 'SC de claim activé '
                  : 'SC de claim désactivé '}
              </span>
              <button
                className='ep-btn'
                onClick={() =>
                  isSCactivated
                    ? sendTx(babyStakingAddress, 'setInactive', [], 0, abiUrl)
                    : sendTx(babyStakingAddress, 'setActive', [], 0, abiUrl)
                }
              >
                {isSCactivated && isSCactivated ? 'Désactiver' : 'Activer'}
              </button>
            </div>
          )}

          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>Add / remove admin</h3>
            <table>
              <tr className='flex items-center gap-x-3 '>
                <td>
                  <input
                    ref={setAdminAddress}
                    type='text'
                    name='admin_address'
                    placeholder='Address'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        babyStakingAddress,
                        'addUserToAdminList',
                        [
                          new TokenIdentifierValue(
                            Buffer.from(setAdminAddress.current.value)
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Add
                  </button>
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        babyStakingAddress,
                        'removeUserFromAdminList',
                        [
                          new TokenIdentifierValue(
                            Buffer.from(setAdminAddress.current.value)
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </table>
          </div>

          {true && (
            <div>
              <h3 className='text-2xl underline mb-3 mt-5'>
                Set Staking parameters (drop rate - duration - couple number)
              </h3>
              <table className='flex items-center gap-x-3 mt-5'>
                <tr>
                  <td>
                    <input
                      ref={opt_drop_rate}
                      type='number'
                      name='opt_drop_rate'
                      placeholder='opt_drop_rate'
                      defaultValue={
                        stakingParams && stakingParams.drop_rate.toNumber()
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      ref={opt_staking_duration}
                      type='number'
                      name='opt_staking_duration'
                      placeholder='opt_staking_duration'
                      defaultValue={
                        stakingParams &&
                        stakingParams.staking_duration.toNumber()
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      ref={opt_allowed_couple_number}
                      type='number'
                      name='opt_allowed_couple_number'
                      placeholder='opt_allowed_couple_number'
                      defaultValue={
                        stakingParams &&
                        stakingParams.allowed_couple_number.toNumber()
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className='ep-btn'
                      onClick={() => {
                        sendTx(
                          babyStakingAddress,
                          'setStakingParams',
                          [
                            new BigUIntValue(
                              new Balance(
                                new Token(),
                                0,
                                opt_drop_rate.current.value
                              ).valueOf()
                            ),
                            new BigUIntValue(
                              new Balance(
                                new Token(),
                                0,
                                opt_staking_duration.current.value
                              ).valueOf()
                            ),
                            new BigUIntValue(
                              new Balance(
                                new Token(),
                                0,
                                opt_allowed_couple_number.current.value
                              ).valueOf()
                            )
                          ],
                          0,
                          abiUrl
                        );
                      }}
                    >
                      Set Stake Settings
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          )}

          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>
              Set Duration & dropBoost
            </h3>
            <table>
              <tr className='flex items-center gap-x-3 '>
                <td>
                  <input
                    ref={setDurationBoost}
                    type='text'
                    name='setDurationBoost'
                    placeholder='1 20 30 50'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        babyStakingAddress,
                        'setDurationBoost',
                        [
                          new TokenIdentifierValue(
                            Buffer.from(setDurationBoost.current.value)
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Set Duration Boost
                  </button>
                </td>
              </tr>
            </table>
            <table>
              <tr className='flex items-center gap-x-3 '>
                <td>
                  <input
                    ref={setDropBoost}
                    type='text'
                    name='setDropBoost'
                    placeholder='1 20 30 50'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        babyStakingAddress,
                        'setDropBoost',
                        [
                          new TokenIdentifierValue(
                            Buffer.from(setDropBoost.current.value)
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Set Drop Boost
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>
              Total babies sent :&nbsp;
              <span className='text-ep-green'>{totalBabiesSent}</span>
            </h3>
          </div>
          {/* End */}
        </div>
      </div>
    </>
  );
}
