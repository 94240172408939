import React from 'react';

export function base64ToDecimal(encodedString: string) {
  const buffer = Buffer.from(encodedString, 'base64');
  const bufString = buffer.toString('hex');
  return parseInt(bufString, 16);
}

export function base64ToHex(encodedString: string) {
  const buffer = Buffer.from(encodedString, 'base64');
  return buffer.toString('hex');
}
