import React, { useRef, useState } from 'react';
import {
  Balance,
  BigUIntValue,
  Token,
  TokenIdentifierValue
} from '@elrondnetwork/erdjs/out';
import { sendTx } from 'components/TxTest/TxFunctions';
import { claimReawardsAdress } from 'config';

export default function StackItem(props: any) {
  const abiUrl = 'assets/json/nft_stake.abi.json';
  const stackId = String.fromCharCode.apply(null, props.stack.collection_id);
  const [gasLimit, setGasLimit] = useState(0);
  const gasValue = useRef() as any;
  // const stackNonce =
  //   props.stack.nonce != '' ? props.stack.nonce.toString() : '';
  const stackNonce =
    props.stack.nonce !== null ? props.stack.nonce.toString() : '';

  const removeStackArgs = () => {
    if (props.stack.nonce !== null) {
      return [
        new TokenIdentifierValue(Buffer.from(stackId)),
        new BigUIntValue(new Balance(new Token(), 0, stackNonce).valueOf())
      ];
    } else {
      return [new TokenIdentifierValue(Buffer.from(stackId))];
    }
  };

  return (
    <tr key={props.index}>
      <td>{stackId}</td>
      <td>{props.stack.timespan_per_unit.toString()}</td>
      <td>{stackNonce}</td>
      <td>
        <button
          onClick={() => {
            sendTx(
              claimReawardsAdress,
              'removeStakeSettings',
              removeStackArgs(),
              0,
              abiUrl,
              [],
              '',
              gasLimit
            );
          }}
          className='ep-btn'
        >
          Remove Stack
        </button>
      </td>
      <td>
        <input
          ref={gasValue}
          type='number'
          onKeyUp={() => {
            console.log(gasValue.current.value);

            setGasLimit(gasValue.current.value);
          }}
          placeholder='Gas Limit'
        />
      </td>
    </tr>
  );
}
