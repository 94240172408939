import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './Rarity.css';
import Card from 'components/Card/Card';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import backArrow from './back.png';

export default function Rarity() {
  //pour les routes
  const params = useParams();
  //On définit la collection
  const [collection, setCollection] = useState();
  const [collectionLink, setCollectionLink] = useState();
  const [collectionName, setCollectionName] = useState();
  const [nftName, setNftName] = useState();
  const [collectionRarity, setCollectionRarity] = useState();
  const [rankingAscending, setRankingAscending] = useState(false);
  const [idAscending, setIdAscending] = useState(true);
  const [searchedId, setSearchedId] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rarityLength, setRarityLength] = useState(0);
  const nftPerPage = 50;
  //liés aux détails
  const [collectionJson, setCollectionJson] = useState();
  const [nftDetails, setNftDetails] = useState();

  //on définit les ref
  const idSearched = useRef();
  //Trick pour reloader le state
  const [reload, setReload] = useState(false);

  //history
  const navigate = useNavigate();

  //On définit les paramètres liés à cette collection
  useEffect(() => {
    // console.log(params.collection);
    // params.collection === undefined
    //   ? setCollection('elrond-pandas')
    //   : setCollection(params.collection);

    if (params.collection == 'baby-pandas') {
      setCollectionLink('BabyPandas');
      setCollectionName('Baby Pandas');
      setNftName('Baby Panda');
      setCollection(params.collection);
    } else if (params.collection == 'fallen-pandas') {
      setCollectionLink('FallenPandas');
      setCollectionName('Fallen Pandas');
      setNftName('Fallen Panda');
      setCollection(params.collection);
    } else {
      setCollectionLink('ElrondPandas');
      setCollectionName('Elrond Pandas');
      setNftName('Elrond Panda');
      setCollection('elrond-pandas');
    }
  });

  //on va chercher la rarity
  useEffect(() => {
    //Le fichier rank
    // console.log(collectionLink);
    collectionLink &&
      fetch(`/collection/${collectionLink}/json/rank.json`)
        .then((response) => {
          const rank_data = response.json();
          return rank_data;
        })
        .then((rank_data) => {
          const rank_data_sorted = Object.entries(rank_data)
            .sort(([, a], [, b]) => a - b)
            .reverse();

          rank_data_sorted.map((item, key) => {
            item.rank = key + 1;
            item.visible = true;
          });
          // console.log(collectionLink);
          // console.log(rank_data_sorted);
          setCollectionRarity(rank_data_sorted);
        })
        .catch(function (error) {
          // console.log(
          //   // eslint-disable-next-line quotes
          //   "Il y a eu un problème avec l'opération fetch: " +
          //     error.message +
          //     `/collection/${collectionLink}/json/rank.json`
          // );
        });

    //Le fichier collection
    collectionLink &&
      fetch(`/collection/${collectionLink}/json/collection.json`)
        .then((response) => {
          const collection_data = response.json();
          return collection_data;
        })
        .then((collection_data) => {
          // console.log(collection_data);
          setCollectionJson(collection_data);
        })
        .catch(function (error) {
          // console.log(
          //   // eslint-disable-next-line quotes
          //   "Il y a eu un problème avec l'opération fetch: " +
          //     error.message +
          //     `/collection/${collectionLink}/json/collection.json`
          // );
        });
  }, [collectionLink]);

  //Le fichier lors d'une requete de détails d'un nft
  useEffect(() => {
    params.id &&
      collectionLink &&
      fetch(`/collection/${collectionLink}/json/${params.id}.json`)
        .then((response) => {
          const nftDetails = response.json();
          return nftDetails;
        })
        .then((nftDetails) => {
          setNftDetails(nftDetails);
        })
        .catch(function (error) {
          // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message + `/collection/${collectionLink}/json/${params.id}.json`);
        });
  }, [params.id, collectionLink]);

  //On creer une liste pour pouvoir ordonner les nft
  const sortRanking = (type) => {
    let key = null;
    let ascending = null;

    if (type === 'byRank') {
      setRankingAscending(!rankingAscending);
      key = 'rank';
      ascending = rankingAscending;
    } else if (type === 'byId') {
      setIdAscending(!idAscending);
      key = 0;
      ascending = idAscending;
    }

    // console.log({rankingAscending, idAscending});

    collectionRarity.sort(function (a, b) {
      return ascending ? a[key] - b[key] : b[key] - a[key]; //Si pas par id, on suppose que c'est by Rank
    });
    // console.log(sortedArray);
  };

  //Pour chercher un nft par id
  const searchById = (e) => {
    const new_rarity = collectionRarity;
    new_rarity.map((item) => {
      if (item[0] == idSearched.current.value) {
        item.searched = true; //Voir comment un item cherché peut être affichés
        // console.log(item);
      } else {
        item.searched = false;
      }
    });
    setCollectionRarity(new_rarity);
    setSearchedId(idSearched.current.value);
    setReload(!reload);
  };

  //Pagination
  let threePoints = false;
  useEffect(() => {
    if (collectionRarity) {
      const pagination = [];
      for (let index = 1; index < rarityLength / nftPerPage + 1; index++) {
        pagination.push(index);
      }
      setPagination(pagination);
    }
  }, [collectionRarity, rarityLength, searchedId]);

  const changePage = (e) => {
    setCurrentPage(e.target.outerText);
  };

  //On trouve la longueur de la rareté en regardant les items visibles
  useEffect(() => {
    let rarityLength = 0;
    // collectionRarity && collectionRarity.map((item)=>{
    //   item.visible && rarityLength++;
    // })
    collectionRarity &&
      collectionRarity.map((item) => {
        rarityLength++;
      });
    setRarityLength(rarityLength);
  });

  if (params.id) {
    //page unique
    return (
      <>
        <div className='ep-container text-ep-slate'>
          <NavbarPanda />
        </div>
        <div className='ep-rarity ep-container text-ep-slate min-h-screen pb-10 mt-10'>
          <img
            onClick={() => navigate(-1)}
            className='hidden w-10 mb-10 md:block cursor-pointer'
            src={backArrow}
            alt='Back arrow'
          />
          <div className='flex flex-col md:flex-row'>
            <div className='md:w-1/4 md:max-w-xs'>
              <img
                className='lazy rounded-xl w-full'
                src={`${
                  process.env.PUBLIC_URL
                }/collection/${collectionLink}/img/${params.id}.${
                  collection === 'fallen-pandas' ? 'jpg' : 'png'
                }`}
                alt={`${nftName} #${params.id}`}
              />
            </div>
            <div className='grow md:px-10'>
              <h2 className='!text-2xl !mb-0 md:!mt-0'>
                <span>Name :</span> {nftName} #{params.id}
              </h2>
              <p className='mb-0'>
                <span>Rarity score :</span>{' '}
                {nftDetails && nftDetails.rarity.rarityScore.toFixed(1)}
              </p>
              <p>
                <span>Rank :</span>{' '}
                {collectionRarity &&
                  collectionRarity.map((item) => {
                    return item[0] == params.id ? item.rank : '';
                  })}{' '}
              </p>
              <h2 className='!text-2xl !mb-2 !mt-5'>Attributes</h2>
              <div className='grid grid-cols-2 gap-5 lg:grid-cols-4'>
                {/* {console.log(collectionJson)} */}
                {nftDetails &&
                  collectionJson &&
                  nftDetails.attributes.map((item) => {
                    // console.log([item.trait_type, item.value]);
                    let rarity =
                      collectionJson[item.trait_type][item.value]
                        .attributeFrequency;
                    rarity = rarity * 100;
                    rarity = rarity.toFixed(2);
                    return (
                      <div
                        key={uuidv4()}
                        className='bg-ep-grey1 rounded-lg text-center py-2'
                      >
                        <p className='font-bold mb-0'>{item.trait_type}</p>
                        <p className='mb-0'>{item.value}</p>
                        <p className='mb-0'>{rarity}%</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    //page de base
    return (
      <>
        <div className='ep-container text-ep-slate'>
          <NavbarPanda />
        </div>
        <div className='ep-rarity ep-container text-ep-slate min-h-screen'>
          <div className='my-10'>
            <input
              type='number'
              ref={idSearched}
              onChange={(e) => searchById(e)}
              className='bg-ep-grey1 py-1 px-2 rounded w-full mb-5 focus:outline-none sm:mr-5 sm:w-auto transition-all hover:bg-slate-600'
              id='search_by_id'
              placeholder='Search by Panda ID'
            />
            <div className='grid grid-cols-2 gap-x-3 sm:inline-block '>
              <button
                onClick={() => sortRanking('byId')}
                className='sort bg-ep-grey1 ep-btn uppercase text-md sm:mr-5 '
                data-sort='rarity_card_id'
              >
                Sort by id
              </button>
              <button
                onClick={() => sortRanking('byRank')}
                className='sort bg-ep-grey1 ep-btn uppercase text-md'
                data-sort='rarity_card_rank'
              >
                Sort by rank
              </button>
            </div>
            <div className='pagination text-center mt-3 md:mt-0 md:float-right'>
              {pagination &&
                pagination.map((item) => {
                  // console.log({item, currentPage});
                  if (item == currentPage) {
                    threePoints = false;
                    return (
                      <div className='bg-ep-grey2 rounded inline-block py-1 px-3 cursor-pointer'>
                        {item}
                      </div>
                    );
                  } else if (
                    item < 2 ||
                    item > pagination.length - 1 ||
                    (item - currentPage <= 1 && item - currentPage >= -1)
                  ) {
                    threePoints = false;
                    return (
                      <div
                        onClick={(e) => changePage(e)}
                        className='inline-block rounded py-1 px-3 mx-1 cursor-pointer hover:bg-slate-600'
                      >
                        {item}
                      </div>
                    );
                  } else {
                    if (!threePoints) {
                      threePoints = true;
                      return <div className='inline-block py-2 px-3'>...</div>;
                    }
                  }
                })}
            </div>
          </div>
          <div
            id='cards-container'
            className='grid grid-cols-2 gap-3 sm:gap-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'
          >
            {collectionRarity &&
              collectionRarity.map((item, key) => {
                if (searchedId != '' && item.searched) {
                  return (
                    <Card
                      key={uuidv4()}
                      id={item[0]}
                      rank={item.rank}
                      link={collectionLink}
                      nftName={nftName}
                      collection={collection}
                      extension={collection === 'fallen-pandas' ? 'jpg' : 'png'}
                    />
                  );
                } else if (
                  !searchedId &&
                  key + 1 <= (currentPage * rarityLength) / pagination.length &&
                  key + 1 >
                    ((currentPage - 1) * rarityLength) / pagination.length
                ) {
                  // console.log(item.visible);
                  return (
                    <Card
                      key={uuidv4()}
                      id={item[0]}
                      rank={item.rank}
                      link={collectionLink}
                      nftName={nftName}
                      collection={collection}
                      extension={collection === 'fallen-pandas' ? 'jpg' : 'png'}
                    />
                  );
                }
              })}
          </div>
          <div className='pagination text-center py-10'>
            {pagination &&
              pagination.map((item) => {
                // console.log({item, currentPage});
                if (item == currentPage) {
                  threePoints = false;
                  return (
                    <div className='bg-ep-grey2 rounded inline-block py-1 px-3 cursor-pointer'>
                      {item}
                    </div>
                  );
                } else if (
                  item < 2 ||
                  item > pagination.length - 1 ||
                  (item - currentPage <= 1 && item - currentPage >= -1)
                ) {
                  threePoints = false;
                  return (
                    <div
                      onClick={(e) => changePage(e)}
                      className='inline-block py-2 px-3 cursor-pointer'
                    >
                      {item}
                    </div>
                  );
                } else {
                  if (!threePoints) {
                    threePoints = true;
                    return <div className='inline-block py-2 px-3'>...</div>;
                  }
                }
              })}
          </div>
        </div>
      </>
    );
  }
}
