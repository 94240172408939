import React, { useState, useRef, useEffect, useContext } from 'react';
import { logout } from '@elrondnetwork/dapp-core';
import { NavLink } from 'react-router-dom';
import { PandaContext } from 'context/PandaContext';
import iconMint from '../../assets/img/icon_mint.png';
import iconNews from '../../assets/img/icon_news.png';
import iconProfile from '../../assets/img/icon_profile.png';
import iconStack from '../../assets/img/icon_stack.png';
import iconVote from '../../assets/img/icon_vote.png';
import iconLogout from '../../assets/img/logout-grey.png';
import pandaCoin from '../../assets/img/panda_coin.png';
import iconShop from '../../assets/img/shop.png';

export default function DashBoardMenu() {
  const [toggleMenu, SetToggleMenu] = useState(false);
  const [widthEl, setWidthEl] = useState() as any;
  const [minWidthEl, setMinWidthEl] = useState() as any;
  const refMenuWidth: any = useRef();
  const [dimension, setDimension] = useState() as any;

  const { pdtValue } = useContext(PandaContext);

  const toggleNav = (bool: boolean) => {
    // console.log(bool);

    SetToggleMenu(bool);
  };

  const resetMenuHeight = () => {
    setWidthEl(null);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeFunc);

    function resizeFunc() {
      setDimension(window.innerWidth);
    }

    resizeFunc();
    return () => {
      window.removeEventListener('resize', resizeFunc);
    };
  }, []);

  useEffect(() => {
    if (dimension < 768) {
      setWidthEl('180px');
      setMinWidthEl('0px');
    } else {
      setWidthEl(`${refMenuWidth.current.scrollWidth}px`);
      setMinWidthEl('');
    }
  }, [dimension]);

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <>
      <div
        ref={refMenuWidth}
        className='ep-dashboard-menu bg-ep-blue2 fixed  h-full w-0 z-20 md:w-12 overflow-hidden transition-all flex  items-center	'
        style={{ width: toggleMenu ? widthEl : minWidthEl }}
        onMouseOver={() => toggleNav(true)}
        onMouseLeave={() => toggleNav(false)}
        onClick={() => toggleNav(false)}
      >
        <div className='ep-nav-menu flex flex-row w-full md:w-auto absolute top-4 left-6 md:hidden'>
          <img
            className='w-5 h-5 inline-block sm:ml-5 mr-1 self-center'
            src={pandaCoin}
            alt='Panda coin'
            title={`${(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(
              0
            )} Panda Coins`}
          />
          <span
            className='self-center ep-nav-item my-1 text-center  sm:text-sm whitespace-nowrap transition-all duration-100 ml-1 mb-2  font-bold text-ep-slate'
            title={`${(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(
              0
            )} Panda Coins`}
          >
            {(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(0)}{' '}
          </span>
        </div>
        <div className='ep-dashboard-menu-links flex flex-col gap-y-12 px-8 md:px-2'>
          <NavLink to='/dashboard/profile'>
            <div className='whitespace-nowrap'>
              <img
                className='w-7 inline-block'
                src={iconProfile}
                alt='Profile Icon'
              />
              <p className='inline-block  ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                Profile
              </p>
            </div>
          </NavLink>
          {/* <NavLink to='/dashboard/mint'>
            <div className='whitespace-nowrap'>
              <img
                className='w-7 inline-block'
                src={iconMint}
                alt='Mint Icon'
              />
              <p className='inline-block  ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                Mint
              </p>
            </div>
          </NavLink> */}
          <NavLink to='/dashboard/news'>
            <div className='whitespace-nowrap	'>
              <img
                className='w-7 inline-block'
                src={iconNews}
                alt='News Icon'
              />
              <p className='inline-block ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                News
              </p>
            </div>
          </NavLink>
          <NavLink to='/dashboard/boostshop'>
            <div className='whitespace-nowrap	'>
              <img
                className='w-7 inline-block'
                src={iconShop}
                alt='Shop Icon'
              />
              <p className='inline-block ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                Shop
              </p>
            </div>
          </NavLink>
          {/* <NavLink to='/dashboard/vote'>
            <div className='whitespace-nowrap	'>
              <img
                className='w-7 inline-block'
                src={iconVote}
                alt='Vote Icon'
              />
              <p className='inline-block  ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                Vote
              </p>
            </div>
          </NavLink> */}
          <NavLink to='/dashboard/stake'>
            <div className='whitespace-nowrap	'>
              <img
                className='w-7 inline-block'
                src={iconStack}
                alt='Stake Icon'
              />
              <p className='inline-block  ml-3 mr-5 text-ep-slate font-semibold text-sm'>
                Stake
              </p>
            </div>
          </NavLink>
          <div
            className='whitespace-nowrap cursor-pointer'
            onClick={handleLogout}
          >
            <img
              className='w-7 inline-block'
              src={iconLogout}
              alt='Logout Icon'
            />
            <p className='inline-block  ml-3 mr-5 text-ep-grey1 font-semibold text-sm'>
              Logout
            </p>
          </div>
        </div>
      </div>
      <button
        onClick={() => toggleNav(!toggleMenu)}
        className='ep-btn fixed right-1/2 bottom-6 translate-x-2/4 text-ep-slate px-8 md:hidden z-30 drop-shadow-xl'
      >
        {toggleMenu ? 'CLOSE' : 'MENU'}
      </button>
    </>
  );
}
