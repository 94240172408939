import React, { useState, useEffect } from 'react';
import loader from '../../../assets/img/spinner-slate.svg';
import NewsItem from './NewsItem';

export default function News() {
  const [news, setNews] = useState() as any;
  useEffect(() => {
    fetch(
      'https://elrondpandas.art/admin/wp-json/wp/v2/news?order=desc&per_page=100&acf_format=standard'
    )
      .then((response) => {
        const wp_data = response.json();
        return wp_data;
      })
      .then((wp_data) => {
        wp_data.map((item: any) => {
          const date = new Date(item.date);
          item.newsDate =
            (date.getUTCMonth() + 1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            }) +
            '/' +
            date.getUTCDate().toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            }) +
            '/' +
            date.getUTCFullYear();
        });

        setNews(wp_data);
      });
  }, []);

  return (
    <div className='dashboard-spacing'>
      {news &&
        news.map((item: any) => (
          <NewsItem
            key={item.id}
            content={item.content.rendered}
            date={item.newsDate}
            title={item.title.rendered}
          />
        ))}
      {!news && (
        <img
          className='fixed right-1/2 bottom-1/2 translate-x-2/4	translate-y-2/4		'
          src={loader}
          alt='spinner'
        />
      )}
    </div>
  );
}
