import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import bambou from './Bamboo.png';
import ContactIcon from './ContactIcon/ContactIcon';
import degrade from './degrade.png';
import DiscordIcon from './Discord_icon.png';
import FaqItem from './FaqItem/FaqItem';
import footPrint from './Footprint_gris.png';
import footPrintLight from './footprint_noir.png';
import InsagramIcon from './Instagram_icon.png';
import imgLogoFull from './logo-full.png';
import pandaHead from './panda_head.png';
import RoadmapItem from './RoadmapItem/RoadmapItem';
import TeamMember from './TeamMember/TeamMember';
import TeamMember2 from './TeamMember/TeamMember2';
import './Index.css';
import TelegramIcon from './Telegram_icon.png';
import TwitterIcon from './twitter_icon.png';
import waveFaq from './wave-faq.png';
import waveIntro from './wave-intro.png';
import waveQuestion from './wave-question.png';
import waveTeam from './wave-team.png';
import waveTeam2 from './wave-team2.png';

export default function Index() {
  /* Permet de capturer l'id de la faq ou le clic a eu lien et de le renvoyer à tous les enfants */
  const [itemId, setitemId] = useState();
  const navigate = useNavigate();
  const getFaqItemId = (itemFaqId) => {
    setitemId(itemFaqId);
    // console.log(id);
  };

  useEffect(() => {
    // const second = 1000,
    // minute = second * 60,
    // hour = minute * 60,
    // day = hour * 24;
    // const mintDate = new Date(Date.UTC(2022, 2, 17, 18, 30, 0));
    // // const mintDate = new Date(Date.UTC(2022, 2, 17, 16, 33, 30));
    // const countDown = mintDate.getTime(),
    // x = setInterval(function() {
    //   const now = new Date().getTime(),
    //   distance = countDown - now;
    //   console.log(distance);
    //   if (distance > 0){
    //     document.getElementById("days").innerText = Math.floor(distance / (day));
    //     document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour));
    //     document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute));
    //     document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);
    //     document.getElementById("mintIsLive").classList.add("hidden");
    //     document.getElementById("countdown").classList.remove("hidden");
    //   }else{
    //     document.getElementById("countdown").classList.add("hidden");
    //     document.getElementById("mintIsLive").classList.remove("hidden");
    //   }
    // })
    // return () => {
    //   clearInterval(x)
    // }
  }, []);

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='ep-index bg-ep-blue text-ep-slate'>
        {/* Intro */}
        <div className='ep-intro'>
          <div className='ep-container h-full grid sm:grid-cols-2'>
            <div className='ep-intro-left my-20 w-60 sm:w-70 lg:w-80 xl:w-92 sm:my-0 sm:ml-24 sm:mr-32 xl:mx-0 justify-self-center self-center	'>
              <img src={imgLogoFull} alt='Logo' className='w-full' />
            </div>
            <div className='ep-intro-right mb-10 sm:mb-0 justify-self-start self-center'>
              <h1 className='sm:text-4xl md:text-5xl xl:text-7xl font-extrabold'>
                About <span className='text-ep-green'>our collection</span>
              </h1>
              <p className='font-medium mt-6 md:mr-12 lg:mr-24 xl:w-112 z-10 relative'>
                Elrond Pandas is a project developed by an ambitious team who
                wishes to develop and make known the WEB 3.0 to the largest
                number. <br />
                To achieve this we have created our token and our universe
                around the pandas. The ecosystem will be built around different
                games and NFT and side projects
                <div id='countdown' className='relative hidden'>
                  <img
                    src={degrade}
                    className='img-degrade absolute z-0 scale-102'
                    alt='Background'
                  />
                  <p className='text-center mb-0 font-light relative z-10'>
                    Second mint in :
                  </p>
                  <ul className='text-center relative z-10'>
                    <li className='font-light'>
                      <span id='days' className='font-medium'></span>days
                    </li>
                    <li className='font-light'>
                      <span id='hours' className='font-medium'></span>Hours
                    </li>
                    <li className='font-light'>
                      <span id='minutes' className='font-medium'></span>Minutes
                    </li>
                    <li className='font-light'>
                      <span id='seconds' className='font-medium'></span>Seconds
                    </li>
                  </ul>
                </div>
                <div id='mintIsLive' className='flex gap-x-5 mt-5 hidden'>
                  <p className='text-3xl mb-0 mt-1'>Mint is live !</p>
                  <a className='' href='https://mint.elrondpandas.art/'>
                    <button className='ep-btn-core bg-ep-green text-ep-slate px-14 py-1 text-2xl'>
                      MINT
                    </button>
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>

        {/* Roadmap */}
        <div className='overflow-hidden ep-roadmap bg-ep-slate text-ep-blue'>
          <img src={waveIntro} alt='Wave background' />
          <div className='ep-container'>
            {/* Container 1 cran en dessous pour que le bg soit compté */}
            <h2 className='text-center !mt-5 sm:!mt-0'>
              R
              <img
                className='h2-panda w-12 inline-block'
                src={pandaHead}
                alt='Panda head'
              />
              admap
            </h2>
            <div className='grid relative grid-cols-1 sm:grid-cols-2 sm:gap-7'>
              <RoadmapItem title='Q1 2022'>
                <ul>
                  <li className='mb-4'>
                    <b>Launch</b> of the Elrond Pandas collection
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of the Panda Token
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of DAO V1
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of weekly rewards
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of Farming: Baby Pandas
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of Elrond Phi
                  </li>
                </ul>
              </RoadmapItem>
              <div className='sm:self-center'>
                <img
                  className='w-32 m-auto my-10'
                  src={footPrint}
                  alt='Footprint'
                />
              </div>
              <RoadmapItem title='Q2 2022' transform='true'>
                <ul>
                  <li className='mb-4'>
                    <b>Launch</b> of the new Elrond Pandas website (DAO,
                    FARMING, SHOP)
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of Elrond Pandas subscriptions
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of the Panda Game
                  </li>
                  <li className='mb-4'>
                    <b>WL launch</b> for 3D Pandas
                  </li>
                  <li className='mb-4'>
                    <b>Drop</b> of the first 3D pandas
                  </li>
                </ul>
              </RoadmapItem>
              <div className='sm:self-center ep-translate-rm-paw'>
                <img
                  className='w-40 m-auto my-10'
                  src={footPrint}
                  alt='Footprint'
                />
              </div>
              <RoadmapItem title='Q3 2022'>
                <ul>
                  <li className='mb-4'>
                    <b>Launch</b> of our clothing brand
                  </li>
                  <li className='mb-4'>
                    <b>Public sale</b> of the 3D pandas
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of Elrond Pandas validator
                  </li>
                  <li className='mb-4'>
                    <b>Launch</b> of the Panda Token on Maiar Exchange
                  </li>
                </ul>
              </RoadmapItem>
              <div className='sm:self-center'>
                <img
                  className='w-52 m-auto my-10'
                  src={footPrint}
                  alt='Footprint'
                />
              </div>
              <img
                className='hidden sm:block absolute top-0 h-full right-2/4 translate-x-2/4'
                src={bambou}
                alt=''
              />
              <RoadmapItem title='Q4 2022' transform='true'>
                Many things to come...
              </RoadmapItem>
              <div className='sm:self-center ep-translate-rm-paw'>
                <img
                  className='w-40 m-auto my-10'
                  src={footPrint}
                  alt='Footprint'
                />
              </div>
            </div>

            <div className='text-center mt-10'>
              <button
                onClick={() => {
                  navigate('/whitepaper/');
                }}
                className='ep-btn-core text-ep-slate bg-ep-green mb-2'
              >
                Whitepaper
              </button>
            </div>
          </div>
        </div>

        {/* Discover our team */}
        <div className='pb-10 overflow-hidden ep-team bg-ep-slate text-ep-blue pt-8 relative xl:pb-0'>
          <div className='ep-container relative'>
            <h2 className='relative z-10 text-ep-slate'>
              Discover <span className='text-ep-green'>our team</span>
            </h2>
            <img
              className='ep-wave-team absolute z-0'
              src={waveTeam}
              alt='Wave team'
            />
            <TeamMember name='MrStorax' inverse={false}>
              Founder of the Elrond Pandas Project and the Elrond Phi Project,
              I’m 24 years old and I have only one goal to do my best to make
              Elrond and its community as big as possible
            </TeamMember>
            <TeamMember name='Rom' inverse='true'>
              Hello, I&apos;m 32 years old and french. I&apos;m like a swiss
              knife as I work on several aspects: Discord administrator,
              marketing, and Python development. Did you received an airdrop
              from us ? It&apos;s me !
            </TeamMember>
            <TeamMember name='DjackDjack' inverse={false}>
              Web developer, I’m 32 years old and I&apos;m in charge of this
              website ! I live in France
            </TeamMember>
            <TeamMember name='Gerrol' inverse='true'>
              Amateur volleyball player and data engineer
            </TeamMember>
            <p className='lg:mb-10 xl:mb-36'></p>
          </div>
          <img
            className='absolute bottom-0'
            src={waveTeam2}
            alt='Wave team 2'
          />
        </div>
        <div className='overflow-hidden ep-team bg-ep-blue text-ep-slate mt-20'>
          <div className='ep-container sm:flex sm:flex-wrap sm:justify-between md:justify-around xl:justify-between md:px-20 xl:px-48'>
            <TeamMember2 name='Nashoba' inverse='true'>
              Hello, I’m an administrator on the Elrond Pandas, I’m 20 years old
              and I’m Belgian !
            </TeamMember2>
            <TeamMember2 name='Faust' inverse='true'>
              Hi, I&apos;m a dad and a game developer
            </TeamMember2>
            <TeamMember2 name='CrypThéo' inverse='true'>
              Hey, I&apos;m 23. I am the project marketing manager and video
              content creator on Elrond
            </TeamMember2>
          </div>
        </div>

        {/* FAQ */}
        <div className='overflow-hidden relative ep-team bg-ep-slate text-ep-blue'>
          <img className='' src={waveFaq} alt='Wave Faq' />
          <div className='ep-container ep-faq md:mx-28'>
            <h2 className='text-center'>FAQ</h2>
            <FaqItem
              getFaqItemId={getFaqItemId}
              giveItemId={itemId}
              question={[
                'How to join the Elrond Pandas ',
                <span key={uuidv4()} className='text-ep-green'>
                  Elrond Pandas
                </span>,
                'family ?'
              ]}
            >
              To join our community you can buy pandas during the upcoming mints
              (we will announce them on Twitter and Discord)
              <br />
              You can also buy pandas on the secondary markets :&nbsp;
              <a
                href='https://deadrare.io/collection/PANDAS-e06423'
                target='_blank'
                rel='noreferrer'
              >
                <button className='ep-btn-core text-ep-slate bg-ep-green mb-2'>
                  Deadrare
                </button>
              </a>
            </FaqItem>
            <FaqItem
              getFaqItemId={getFaqItemId}
              giveItemId={itemId}
              question={[
                'Where can I see my NFT ',
                <span key={uuidv4()} className='text-ep-green'>
                  Elrond Pandas
                </span>,
                '?'
              ]}
            >
              From now on, you can find your NFT Elrond Pandas in your Maiar
              application, in your Portfolio and you can also admire them on our
              website in the&nbsp;
              <a
                className='text-ep-green font-medium cursor-pointer'
                onClick={() => {
                  navigate('/dashboard/');
                }}
              >
                dashboard section
              </a>
              .
            </FaqItem>
            <FaqItem
              getFaqItemId={getFaqItemId}
              giveItemId={itemId}
              question='WHAT ARE BABY PANDAS?'
            >
              A baby comes from the mating of a father and a mother panda,
              originally our pandas had to go and reproduce in a farm for 30
              days to hope to have a baby. With the innovation of technology we
              can now reduce the time and boost the statistics of luck! <br />
              The baby pandas have a supply of 2500 babies and will never be
              offered for sale by Elrond Pandas, they are your babies and you
              are the master of supply and demand!
            </FaqItem>
            <FaqItem
              getFaqItemId={getFaqItemId}
              giveItemId={itemId}
              question='WHAT IS A PANDA FAMILY?'
            >
              A panda family is the gathering of a father and a mother panda + a
              baby panda! Owning a family opens the doors to many breeding
              farms, only for families.
            </FaqItem>
          </div>
          <img src={waveQuestion} alt='Wave Question' />
        </div>

        {/* Contact */}
        <div className='ep-contact pb-10 overflow-hidden ep-team bg-ep-blue text-ep-slate relative xl:pb-0'>
          <div className='ep-container relative'>
            <h2 className='text-center !text-3xl !font-bold !mb-3'>
              You have more questions?
            </h2>
            <p className='text-center uppercase'>
              Ask us on Discord or by email at <br />
              elrondpanda@gmail.com <br />
              We will try to regularly update our faq
            </p>
            <div className='grid gap-y-10 px-5 grid-cols-2 sm:px-0 sm:flex sm:justify-between my-20 sm:mx-20 md:mx-40 lg:mx-60'>
              <ContactIcon
                link='https://discord.com/invite/XhyYvcAC4V'
                image={DiscordIcon}
                alt='Discord Icon'
              />
              <ContactIcon
                link='https://twitter.com/ElrondPandas'
                image={TwitterIcon}
                alt='Twitter Icon'
              />
              <ContactIcon
                link='https://www.instagram.com/elrondpandas/'
                image={InsagramIcon}
                alt='Instagram Icon'
              />
              <ContactIcon
                link='https://t.me/+M9wCJO-bnxE1MDJk'
                image={TelegramIcon}
                alt='Telegram Icon'
              />
            </div>

            <h2 className='text-center !text-3xl !font-bold !mb-3 !mt-0'>
              Newsletter
            </h2>
            <form
              className='text-center mb-20'
              id='sib-form'
              method='POST'
              action='https://1b5bb5ff.sibforms.com/serve/MUIEALm7nuwWPZLZhhPPzXnwZ9g3_7b5K_m6sMQ1AToQ21iVna0Sj2i8bLT2kyFee7lmMEOzc-1a2h95lY4wmGMKNq_pEmrgPDusSYnglK4n6CYbAMctw4LX4hflmFNCLd4vjiw3uxU-Ydf3e8XpNidkOdYfKfl9fXt3EUP_KBl9zDAtTdKByFx55OLF8GUHt99CG5ac4ose5uxD'
              data-type='subscription'
            >
              <div className='mb-5 flex justify-center gap-x-5 gap-y-3 flex-wrap'>
                <input
                  className='bg-ep-blue border-2 border-ep-green rounded w-40 pl-3'
                  type='text'
                  name='NOM'
                  id='NOM'
                  placeholder='Name'
                />
                <input
                  className='bg-ep-blue border-2 border-ep-green rounded w-40 pl-3'
                  type='email'
                  name='EMAIL'
                  id='EMAIL'
                  placeholder='Email'
                />
              </div>
              <input
                className='ep-btn-core text-ep-slate bg-ep-green cursor-pointer'
                type='submit'
                value='Send Message'
              />
            </form>
          </div>
          <img
            className='ep-paw1-contact absolute hidden lg:block w-52'
            src={footPrintLight}
            alt='Panda footprint'
          />
          <img
            className='ep-paw2-contact absolute hidden lg:block w-32'
            src={footPrintLight}
            alt='Panda footprint'
          />
        </div>
      </div>
    </>
  );
}
