import React, { useState, useEffect, useContext } from 'react';
import './Mint.scss';
import {
  transactionServices,
  useGetAccountInfo,
  useGetPendingTransactions
} from '@elrondnetwork/dapp-core';
import { U32Value } from '@elrondnetwork/erdjs/out';
// import { Button } from 'react-bootstrap';
import { Button, Modal } from 'react-daisyui';
import { ErrorModal, MintModal } from 'components/Modal/Modals';
import SocialLink from 'components/SocialLink/SocialLink';
import { ApiCall } from 'components/TxTest/ApiCall';
import { base64ToDecimal } from 'components/TxTest/functions';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import { gateway, mintAddress, pandaToken2 as pandaToken } from 'config';
import { PandaContext } from 'context/PandaContext';

export default function Mint() {
  const abiMintUrl = '../../assets/json/mint.json';
  const [mintNumber, setMintNumber] = useState(1);
  const [mintPriceEgld, setMintPriceEgld] = useState(1);
  const [mintPricePt, setMintPricePt] = useState(1);
  const [maxNftPerWallet, setMaxNftPerWallet] = useState(10);
  const [totalNftsLeft, setTotalNftsLeft] = useState('-');
  const [nftsLeftForPt, setNftsLeftForPt] = useState('-');
  const [dropId, setDropId] = useState();
  const { address, account } = useGetAccountInfo();
  const [txId, setTxID] = useState(null) as any;
  const [mintModalShow, setMintModalShow] = useState(false);
  const [curentBuyPandasId, setCurentBuyPandasId] = useState([]) as any;
  const [apiLoaded, setApiLoaded] = useState(false);

  const { setRefreshPdtValue, refreshPdtValue, pandasMinted, setPandasMinted } =
    useContext(PandaContext);
  const [refresh, setRefresh] = useState(0);
  const { pendingTransactionsArray, hasPendingTransactions } =
    useGetPendingTransactions();

  const successfulTx: any = transactionServices.useGetSuccessfulTransactions();

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: txId,
    onSuccess: async () => {
      setRefresh(refresh + 1);
      setRefreshPdtValue(refreshPdtValue + 1);
      console.log('success: ' + txId);
      //Va chercher le panda minté si un tx est successfull
      const hashOfTheMint =
        successfulTx.successfulTransactions[txId].transactions[0].hash;

      try {
        //on va chercher la transaction sur l'api
        const response = await fetch(gateway + 'transactions/' + hashOfTheMint);
        // console.log(gateway + 'transactions/' + hashOfTheMint);
        const json: any = await response.json();
        console.log(json);
        //on va chercher les pandas mintés de la transaction
        const pandasIds: Array<number> = [];
        json.logs.events.map((item: any) => {
          if (
            item.identifier === 'ESDTNFTTransfer' ||
            item.identifier === 'MultiESDTNFTTransfer'
          ) {
            const pandaId = base64ToDecimal(item.topics[1]);
            pandasIds.push(pandaId);
          }
        });
        setCurentBuyPandasId(pandasIds);
        //On affiche le modal
        const element: any = document.getElementById('mintModal');
        element.checked = true;
      } catch (error) {
        console.log(error);
      }
    },
    onFail: () => {
      console.log('fail');
    },
    onCancelled: () => {
      console.log('cancel');
    }
  });

  useEffect(() => {
    if (hasPendingTransactions) {
      setTxID(pendingTransactionsArray[0][0]);
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    //getMintInfo
    let apiError = 0;
    const getMintInfo = () => {
      querySc(mintAddress, 'getMintInfo', abiMintUrl, [])
        .then((res) => {
          // console.log(res);
          setMintPriceEgld(
            res.mint_price_in_egld.toNumber() * Math.pow(10, -18)
          );
          setMintPricePt(res.mint_price_in_pt.toNumber() * Math.pow(10, -18));
          setMaxNftPerWallet(res.max_nfts_per_wallet.toNumber());
          setApiLoaded(true);
        })
        .catch((err) => {
          apiError++;
          if (apiError < 3) {
            getMintInfo();
          } else {
            //On affiche le modal
            const element: any = document.getElementById('errorModal');
            element.checked = true;
          }
        });
    };
    getMintInfo();
  }, []);

  useEffect(() => {
    //getMintStats
    let apiError = 0;
    const getMintStats = () => {
      querySc(mintAddress, 'getMintStats', abiMintUrl, [])
        .then((res) => {
          // console.log(res);
          // console.log(res.total_nfts_left.toNumber());
          setTotalNftsLeft(res.total_nfts_left.toNumber());
          setNftsLeftForPt(res.nfts_left_for_pt.toNumber());
        })
        .catch((err) => {
          apiError++;
          if (apiError < 3) {
            getMintStats();
          } else {
            //On affiche le modal
            const element: any = document.getElementById('errorModal');
            element.checked = true;
          }
        });
    };
    getMintStats();
  }, []);

  useEffect(() => {
    //getDropId
    let apiError = 0;
    const getDropId = () => {
      querySc(mintAddress, 'getDropId', abiMintUrl, [])
        .then((res) => {
          // console.log(res);
          setDropId(res.toNumber());
        })
        .catch((err) => {
          apiError++;
          if (apiError < 3) {
            getDropId();
          } else {
            //On affiche le modal
            const element: any = document.getElementById('errorModal');
            element.checked = true;
          }
        });
    };
    getDropId();
  }, []);

  return (
    <div className='ep-mint flex flex-col  justify-center relative'>
      <div className='self-center text-center mb-56 mt-12 md:mt-0 md:mb-32'>
        <div className='mint-container text-ep-slate z-10'>
          <div>
            <h2 className='!mb-5'>
              <strong>
                MINT #<span className='inline-block -translate-y-2.5	'>3</span>{' '}
              </strong>
            </h2>
            <h5 className='text-2xl mb-2'>
              Mint price :{' '}
              <strong>
                {mintPriceEgld} EGLD
                {/*  / {mintPricePt.toFixed(0)} Panda Tokens */}
              </strong>
            </h5>
            <h5 className='text-2xl mb-2 line-through hidden'>
              {' '}
              Max 7 NFTs per transaction
            </h5>
            <h5 className='text-2xl'>Max {maxNftPerWallet} NFTs per wallet</h5>
            <div className='how-many mb-4 mt-6'>
              <button
                onClick={() => {
                  if (mintNumber > 1) {
                    setMintNumber(mintNumber - 1);
                  }
                }}
                id='less'
                className={`${
                  apiLoaded
                    ? 'bg-ep-green'
                    : 'bg-ep-grey2 cursor-not-allowed border-ep-grey2'
                } bg-ep-green text-2xl ep-btn-rounded relative z-10`}
              >
                -
              </button>
              <span className='text-3xl'>{mintNumber}</span>
              <button
                onClick={() => {
                  if (mintNumber < maxNftPerWallet) {
                    setMintNumber(mintNumber + 1);
                  }
                }}
                id='more'
                className={`${
                  apiLoaded
                    ? 'bg-ep-green'
                    : 'bg-ep-grey2 cursor-not-allowed border-ep-grey2'
                } bg-ep-green text-2xl ep-btn-rounded relative z-10`}
              >
                +
              </button>
            </div>
            <h5 className='tx-amount text-xl mb-2'>
              {mintPriceEgld * mintNumber} EGLD
              {/*/ {(mintPricePt * mintNumber).toFixed(0)} PT */}
            </h5>
            <h5 className='remaining-NFTs text-xl mb-2'>
              <strong>{totalNftsLeft} / 5000 </strong>NFTs minted
            </h5>
            <div
              className={`${
                apiLoaded
                  ? 'bg-ep-green cursor-pointer'
                  : 'bg-ep-grey2 cursor-not-allowed border-ep-grey2'
              } mint-button ep-btn-core   text-ep text-4xl text-ep-slate off relative z-10`}
              onClick={() => {
                if (dropId) {
                  const mintTx: any = sendTx(
                    mintAddress,
                    'buyRandomNft',
                    [new U32Value(mintNumber)],
                    mintPriceEgld * mintNumber,
                    abiMintUrl
                  );
                  // const mintTx: any = sendTx(
                  //   mintAddress,
                  //   'buyRandomNft',
                  //   [new U32Value(mintNumber)],
                  //   0,
                  //   abiMintUrl,
                  //   [],
                  //   address,
                  //   0,
                  //   [pandaToken, (mintPricePt * mintNumber).toFixed(0)]
                  // );
                  setTxID(mintTx.sessionId);
                }
              }}
            >
              Mint
            </div>
          </div>
        </div>
      </div>

      <MintModal show={mintModalShow} pandasIds={curentBuyPandasId} />
      <ErrorModal
        title={'Something went wrong'}
        message={
          'Please refresh page or contact an administrator if the problem persists'
        }
      />
      <SocialLink />
    </div>
  );
}
