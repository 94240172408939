import React from 'react';
import './RoadmapItem.css';

export default function RoadmapItem(props) {
  return (
    <div
      className={`sm:self-center sm:w-3/4 sm:justify-self-end sm:mr-14 ${
        props.transform && 'ep-translate-rm'
      } xl:w-4/6`}
    >
      <h3 className='uppercase mb-3'>{props.title}</h3>
      <p>{props.children}</p>
    </div>
  );
}
