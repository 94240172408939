import React from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
  TokenIdentifierValue,
  BigUIntValue,
  Balance,
  Token
} from '@elrondnetwork/erdjs/out';
import { Link } from 'react-router-dom';
import './Card.css';
import { sendTx } from 'components/TxTest/TxFunctions';
import { claimReawardsAdress } from 'config';

export default function Card(props) {
  const abiStakeUrl = '../../assets/json/nft_stake.abi.json';
  const { address } = useGetAccountInfo();

  const stackNft = async () => {
    // const scMethod = props.staked === 'true' ? 'claimThenUnstake' : 'stake';
    if (props.staked === 'true') {
      if (props.sftPartner === true) {
        const { sessionId, error } = await sendTx(
          claimReawardsAdress,
          'claimThenUnstake',
          [
            new TokenIdentifierValue(Buffer.from(props.panda.collection)),
            new BigUIntValue(
              new Balance(new Token(), 0, props.panda.nonce).valueOf()
            )
          ],
          0,
          abiStakeUrl
        );
        //renvoit vers le dashboard
        props.sendTxId(sessionId);
      } else {
        const { sessionId, error } = await sendTx(
          claimReawardsAdress,
          'claimThenUnstake',
          [
            new TokenIdentifierValue(Buffer.from(props.panda.identifier)),
            new BigUIntValue(
              new Balance(new Token(), 0, props.panda.nonce).valueOf()
            )
          ],
          0,
          abiStakeUrl
        );
        //renvoit vers le dashboard
        props.sendTxId(sessionId);
      }
    } else {
      console.log(props.panda.collection, props.panda.nonce);
      const { sessionId, error } = await sendTx(
        claimReawardsAdress,
        'stake',
        [],
        0,
        abiStakeUrl,
        [[props.panda.collection, props.panda.nonce]],
        address
      );
      //renvoit vers le dashboard
      props.sendTxId(sessionId);
    }
  };

  return (
    <div
      className={`rarity_card ${
        props.staked === 'true' ? 'bg-ep-green' : 'bg-ep-grey1'
      } ${
        props.className && props.className
      } rounded-xl py-4 px-3 transition-all duration-200 hover:scale-102 hover:bg-slate-600`}
    >
      <div className='rarity_card_img'>
        {props.sftPartner ? (
          <img
            className='lazy rounded-xl'
            src={props.link}
            alt={`${props.nftName} #${props.id}`}
          />
        ) : (
          <Link to={`/rarity/${props.collection}/${props.id}`}>
            <img
              className='lazy rounded-xl'
              src={`${process.env.PUBLIC_URL}/collection/${props.link}/img/${props.id}.${props.extension}`}
              alt={`${props.nftName} #${props.id}`}
            />
          </Link>
        )}
      </div>
      {props.rank && (
        <div className='rarity_card_rank text-center'>Rank {props.rank}</div>
      )}
      <div className='rarity_card_name text-center uppercase'>
        {props.sftPartner ? (
          <>{props.collection}</>
        ) : (
          <Link to={`/rarity/${props.collection}/${props.id}`}>
            {props.nftName} #<span className='rarity_card_id'>{props.id}</span>
          </Link>
        )}
      </div>
      {props.stakeInfo && (
        <div className='text-center'>
          <button
            className='ep-btn text-ep-slate bg-ep-blue mb-2 hover:bg-ep-blue hover:hue-rotate-30	'
            onClick={() => stackNft()}
          >
            {props.staked == 'true' ? 'Unstake' : 'Stake'}
          </button>
        </div>
      )}
    </div>
  );
}
