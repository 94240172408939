import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import './Navitem.css';

export default function Navitem(props) {
  const [toggleSubMenu, SetToggleSubMenu] = useState(false);
  const [heightElSub, setHeightElSub] = useState();
  const refSubMenuHeight = useRef();

  const toggleNav = () => {
    SetToggleSubMenu(!toggleSubMenu);
    props.resetMenu();
  };

  const openNav = () => {
    if (window.innerWidth > 640) {
      SetToggleSubMenu(true);
    }
  };

  const closeNav = () => {
    if (window.innerWidth > 640) {
      SetToggleSubMenu(false);
    }
  };

  return (
    <div
      onMouseOver={openNav}
      onMouseLeave={closeNav}
      className='ep-nav-menu flex flex-col w-full md:w-auto'
    >
      {props.link ? (
        <NavLink
          to={props.link}
          onClick={props.toggleNav}
          className={`self-center ep-nav-item my-1 cursor-pointer w-full text-center sm:ml-5 sm:text-sm ${props.class} whitespace-nowrap transition-all duration-100 sm:w-auto`}
        >
          {props.children}
        </NavLink>
      ) : (
        <a
          onClick={toggleNav}
          className={`self-center ep-nav-item my-1 cursor-pointer w-full text-center sm:ml-5 sm:text-sm ${props.class} whitespace-nowrap transition-all duration-100 hover:text-ep-slate`}
        >
          {props.children}
        </a>
      )}
      {props.submenu && (
        <div
          ref={refSubMenuHeight}
          className='ep-nav-sub_menu overflow-hidden sm:absolute flex flex-col justify-end md:top-full bg-ep-blue'
          style={{ height: toggleSubMenu ? heightElSub : '0px' }}
        >
          {' '}
          {props.submenu.map((item, key) => {
            return (
              <NavLink
                key={uuidv4()}
                onClick={props.toggleNav}
                to={`/rarity/${props.subLink[key]}`}
                className={`ep-nav-item my-1 cursor-pointer self-center text-gray-500 sm:ml-5 sm:text-sm ${props.class} whitespace-nowrap transition-all duration-100 md:text-ep-slate`}
              >
                {item}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
}
