import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './WpCore.css';

export default function WpCore(props) {
  const menuArray = props.menu;
  menuArray.sort((a, b) => a.acf.order - b.acf.order);
  // console.log(menuArray);
  const imgWidth = '1536x1536';
  console.log(props.whitepaper);
  console.log(props.lang);
  return (
    <div
      className='ep-wp-core basis-full overflow-auto bg-ep-slate'
      style={{ flexShrink: 9999 }}
    >
      {props.page === undefined && ( //Si aucune menu sélectionné
        <>
          <div className='w-full h-60'>
            <img
              key={uuidv4()}
              className='w-full h-full object-cover object-center'
              src={props.whitepaper[0].acf.image.sizes[imgWidth]}
              alt='Banner'
            />
          </div>
          <div className='px-10 md:pl-20 md:pr-52 pb-20 md:pb-10'>
            <h1
              className='text-base'
              style={{ fontSize: 0 }}
              key={uuidv4()}
              dangerouslySetInnerHTML={{
                __html: props.whitepaper[0].title.rendered
              }}
            ></h1>
            <div
              key={uuidv4()}
              dangerouslySetInnerHTML={{
                __html: props.whitepaper[0].content.rendered
              }}
            ></div>
          </div>
        </>
      )}
      {props.whitepaper.map((item) => {
        if (item.slug == props.page) {
          return (
            <>
              {item.acf.image && (
                <div className='w-full h-60'>
                  <img
                    key={uuidv4()}
                    className='w-full h-full object-cover object-center'
                    src={item.acf.image.sizes[imgWidth]}
                    alt='Banner'
                  />
                </div>
              )}
              <div className='px-10 md:pl-20 md:pr-52 pb-20 md:pb-10'>
                <h1
                  className='text-base'
                  style={{ fontSize: 0 }}
                  key={uuidv4()}
                  dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                ></h1>
                <div
                  key={uuidv4()}
                  dangerouslySetInnerHTML={{ __html: item.content.rendered }}
                ></div>
              </div>
            </>
          );
        }
      })}
    </div>
  );
}
