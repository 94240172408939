import { React, useEffect, useState, useLayoutEffect } from 'react';
import './Whitepaper.css';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import WpCore from './WpCore/WpCore';
import WpMenu from './WpMenu/WpMenu';
import WpNavBar from './WpNavBar/WpNavBar';

export default function Whitepaper() {
  //Usestate divers
  const [menuEN, setMenuEN] = useState();
  const [whitepaperEN, setwhitepaperEN] = useState();
  const [menuFR, setMenuFR] = useState();
  const [whitepaperFR, setwhitepaperFR] = useState();
  const [menuRO, setMenuRO] = useState();
  const [whitepaperRO, setwhitepaperRO] = useState();
  const params = useParams();
  const navigate = useNavigate();

  //On va chercher toutes les données du whitepaper et du menu dans la bonne langue
  useEffect(() => {
    fetch(
      'https://elrondpandas.art/admin/wp-json/wp/v2/whitepaper-en?orderby=menu_order&order=asc&per_page=100&acf_format=standard'
    )
      .then((response) => {
        const wp_data = response.json();
        return wp_data;
      })
      .then((wp_data) => {
        setwhitepaperEN(wp_data);
        console.log(wp_data);
      });

    fetch(
      'https://elrondpandas.art/admin/wp-json/wp/v2/whitepaper-fr?orderby=menu_order&order=asc&per_page=100&acf_format=standard'
    )
      .then((response) => {
        const wp_data = response.json();
        return wp_data;
      })
      .then((wp_data) => {
        setwhitepaperFR(wp_data);
        console.log(wp_data);
      });

    fetch(
      'https://elrondpandas.art/admin/wp-json/wp/v2/whitepaper-ro?orderby=menu_order&order=asc&per_page=100&acf_format=standard'
    )
      .then((response) => {
        const wp_data = response.json();
        return wp_data;
      })
      .then((wp_data) => {
        setwhitepaperRO(wp_data);
        // console.log(wp_data);
      });

    fetch('https://elrondpandas.art/admin/wp-json/wp/v2/menu-en?per_page=100')
      .then((response) => {
        const menu_data = response.json();
        return menu_data;
      })
      .then((menu_data) => {
        setMenuEN(menu_data);
        // console.log(menu_data);
      });

    fetch('https://elrondpandas.art/admin/wp-json/wp/v2/menu-fr?per_page=100')
      .then((response) => {
        const menu_data = response.json();
        return menu_data;
      })
      .then((menu_data) => {
        setMenuFR(menu_data);
        // console.log(menu_data);
      });

    fetch('https://elrondpandas.art/admin/wp-json/wp/v2/menu-ro?per_page=100')
      .then((response) => {
        const menu_data = response.json();
        return menu_data;
      })
      .then((menu_data) => {
        setMenuRO(menu_data);
        // console.log(menu_data);
      });
  }, []);

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='whitepaper flex ep-wp-down relative'>
        {(params.lang == 'en' || params.lang === undefined) &&
          menuEN &&
          whitepaperEN && (
            <>
              <WpMenu menu={menuEN} whitepaper={whitepaperEN} lang={'en'} />
              <WpCore
                page={params.page}
                whitepaper={whitepaperEN}
                menu={menuEN}
                lang={params.lang}
              />
            </>
          )}
        {params.lang == 'fr' && menuFR && whitepaperFR && (
          <>
            <WpMenu
              menu={menuFR}
              whitepaper={whitepaperFR}
              lang={params.lang}
            />
            <WpCore
              page={params.page}
              whitepaper={whitepaperFR}
              menu={menuFR}
              lang={params.lang}
            />
          </>
        )}
        {params.lang == 'ro' && menuRO && whitepaperRO && (
          <>
            <WpMenu
              menu={menuRO}
              whitepaper={whitepaperRO}
              lang={params.lang}
            />
            <WpCore
              page={params.page}
              whitepaper={whitepaperRO}
              lang={params.lang}
              menu={menuFR}
            />
          </>
        )}
      </div>
    </>
  );
}
