import React, { useContext, useEffect, useState } from 'react';
import { U32Value } from '@elrondnetwork/erdjs/out';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Card from 'components/Card/Card';
import { sendTx } from 'components/TxTest/TxFunctions';
import {
  babyStakingAddress,
  boostShopLuckSft,
  boostShopLuckSft15,
  boostShopLuckSft30,
  boostShopLuckSft40,
  boostShopLuckSft50,
  boostShopSft,
  boostShopSft15,
  boostShopSft30,
  boostShopSft40,
  boostShopSft50
} from 'config';
import { PandaContext } from 'context/PandaContext';
import ticket15 from '../../assets/img/boost/ticket-15.png';
import ticket30 from '../../assets/img/boost/ticket-30.png';
import ticket40 from '../../assets/img/boost/ticket-40.png';
import ticket50 from '../../assets/img/boost/ticket-50.png';
import ticket15Luck from '../../assets/img/boost/ticket-luck-15.png';
import ticket30Luck from '../../assets/img/boost/ticket-luck-30.png';
import ticket40Luck from '../../assets/img/boost/ticket-luck-40.png';
import ticket50Luck from '../../assets/img/boost/ticket-luck-50.png';

export function MintModal(props: any) {
  // console.log(props.show);
  return (
    <>
      <input
        type='checkbox'
        id='mintModal'
        className='daisy-modal-toggle'
        // checked={props.show}
      />
      <div className='daisy-modal'>
        <div className='daisy-modal-box w-11/12 max-w-5xl'>
          <h3 className='font-bold text-3xl text-slate-900 text-center'>
            Congratulations !
          </h3>
          <p className='py-4 text-slate-700 text-center'>
            You&apos;ve just minted the following Panda
            {props.pandasIds.length > 1 && 's'} !
          </p>
          <div className='flex flex-wrap gap-x-5 justify-evenly'>
            {props.pandasIds.map((id: any) => (
              <div key={id} className='w-52 flex-none'>
                <Card
                  key={uuidv4()}
                  id={id}
                  link={'BabyPandas'}
                  nftName={'Baby Panda'}
                  collection={'baby-pandas'}
                  extension='png'
                  className='mb-5'
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  // rank={
                  //   babyPandaRarity &&
                  //   babyPandaRarity.find(
                  //     (item: any) =>
                  //       item[0] === panda.name.replace('Baby Panda #', '')
                  //   )['rank']
                  // }
                />
              </div>
            ))}
          </div>
          <p className='py-4 text-slate-700 text-center'>
            Une autre phrase ici ?
          </p>
          <div className='daisy-modal-action'>
            <label htmlFor='mintModal' className='daisy-btn'>
              Yay!
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export function ErrorModal(props: any) {
  // console.log(props.show);

  return (
    <>
      <input
        type='checkbox'
        id='errorModal'
        className='daisy-modal-toggle'
        // checked={props.show}
      />
      <div className='daisy-modal'>
        <div className='daisy-modal-box'>
          <h3 className='font-bold text-3xl text-slate-900 text-center'>
            {props.title}
          </h3>
          <p className='py-4 text-slate-700 text-center'>
            Please refresh page or contact an administrator if the problem
            persists
          </p>
          <p className='py-4 text-slate-700 text-center'>
            ⠀⠀⠀⠀⠀⢀⣴⣶⣦⡄⢀⣀⣀⣀⣀⣀⢀⣴⣶⣦⣄⠀⠀⠀⠀⠀
            <br />
            ⠀⠀⠀⠀⠀⣿⣿⣿⠟⠉⠀⠀⠀⠀⠀⠀⠉⠛⢿⣿⣿⡄⠀⠀⠀⠀
            <br />
            ⠀⠀⠀⠀⠀⠙⠿⠁⠀⣠⣤⡄⠀⠀⢠⣤⣄⠀⠈⢿⠟⠀⠀⠀⠀⠀
            <br />
            ⠀⠀⠀⠀⠀⠀⠇⠀⢸⣿⣿⠳⣶⣶⡞⢿⣿⡇⢠⣼⢶⢶⣤⡀⠀⠀
            <br />
            ⠀⠀⢀⣾⣿⣿⣿⣦⠈⠛⠋⠐⠤⠣⠀⠘⠛⠁⢿⡽⠛⠛⣼⡇⠀⠀
            <br />
            ⠉⠉⠙⠿⢿⣿⡿⠟⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉
            <br />
          </p>
          <div className='daisy-modal-action'>
            <label htmlFor='errorModal' className='daisy-btn'>
              Yay!
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export function SuccessModal(props: any) {
  // console.log(props.show);

  return (
    <>
      <input type='checkbox' id='successModal' className='daisy-modal-toggle' />
      <div className='daisy-modal'>
        <div className='daisy-modal-box'>
          <h3 className='font-bold text-3xl text-slate-900 text-center'>
            {props.title}
          </h3>
          <p
            className='py-4 text-slate-700 text-center'
            dangerouslySetInnerHTML={{
              __html: props.message
            }}
          ></p>
          {props.babyPandaId > 0 && (
            <div>
              <Card
                id={props.babyPandaId}
                link={'BabyPandas'}
                nftName={'Baby Panda'}
                collection={'baby-pandas'}
                extension='png'
                className='mb-5'
                // eslint-disable-next-line react/jsx-no-duplicate-props
                // rank={
                //   babyPandaRarity &&
                //   babyPandaRarity.find(
                //     (item: any) =>
                //       item[0] === panda.name.replace('Baby Panda #', '')
                //   )['rank']
                // }
              />
            </div>
          )}
          <div className='daisy-modal-action'>
            <label htmlFor='successModal' className='daisy-btn'>
              {props.buttonText}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export function BoostChoiceModal(props: any) {
  // console.log(props.show);
  const { userBoosts, refreshUserBoosts, setRefreshUserBoosts } =
    useContext(PandaContext);

  const applyBoost = (type: string, boost: string) => {
    const nonce = parseInt(boost.slice(-2), 16);
    props.setTxType('boost');
    const boostTx: any = sendTx(
      babyStakingAddress,
      type == 'time' ? 'addDurationBoost' : 'addDropBoost',
      [new U32Value(props.coupleIdToBoost)],
      0,
      props.abiUrl,
      [[type == 'time' ? boostShopSft : boostShopLuckSft, nonce]],
      props.userAddress,
      7000000
    );
    props.setTxID(boostTx.sessionId);
  };

  const boostCount =
    userBoosts.boostTime50 +
    userBoosts.boostTime40 +
    userBoosts.boostTime30 +
    userBoosts.boostTime15 +
    userBoosts.boostLuck50 +
    userBoosts.boostLuck40 +
    userBoosts.boostLuck30 +
    userBoosts.boostLuck15;

  return (
    <>
      <input
        type='checkbox'
        id='mintModal'
        className='daisy-modal-toggle'
        checked={props.show}
        onChange={(e) => {}} // eslint-disable-line
      />
      <div className='daisy-modal'>
        <div className='daisy-modal-box relative w-11/12 max-w-5xl'>
          <label
            className='daisy-btn daisy-btn-sm daisy-btn-circle absolute right-2 top-2 cursor-pointer'
            onClick={() => props.boostModalShowToggle()}
          >
            ✕
          </label>

          <h3 className='font-bold text-3xl text-slate-900 text-center mb-5'>
            Choose your boost
          </h3>
          <div>
            {!boostCount ? (
              <p className='text-center text-slate-700'>
                You don&apos;t have any boosts yet. You can buy them in the
                &nbsp;
                <Link
                  to='/dashboard/boostshop'
                  className='text-ep-green font-bold'
                >
                  shop
                </Link>
                .
              </p>
            ) : (
              <div className='grid grid-cols-1 md:grid-cols-2'>
                <div>
                  <h3 className='!text-3xl !mb-6 text-center !normal-case text-ep-grey1'>
                    Boost of time
                  </h3>
                  {!userBoosts.boostTime50 &&
                    !userBoosts.boostTime40 &&
                    !userBoosts.boostTime30 &&
                    !userBoosts.boostTime15 && (
                      <p className='text-ep-grey1 mb-5 text-center'>
                        You don&apos;t have any boost of time
                      </p>
                    )}
                  <>
                    {userBoosts.boostTime50 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket50}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('time', boostShopSft50);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostTime40 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket40}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('time', boostShopSft40);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostTime30 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket30}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('time', boostShopSft30);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostTime15 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket15}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('time', boostShopSft15);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                </div>
                <div>
                  <h3 className='!text-3xl !mb-6 text-center !normal-case text-ep-grey1'>
                    Boost of Luck
                  </h3>
                  {!userBoosts.boostLuck50 &&
                    !userBoosts.boostLuck40 &&
                    !userBoosts.boostLuck30 &&
                    !userBoosts.boostLuck15 && (
                      <p className='text-ep-grey1 mb-5 text-center'>
                        You don&apos;t have any boost of luck
                      </p>
                    )}
                  <>
                    {userBoosts.boostLuck50 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket50Luck}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('luck', boostShopLuckSft50);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostLuck40 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket40Luck}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('luck', boostShopLuckSft40);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostLuck30 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket30Luck}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('luck', boostShopLuckSft30);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                  <>
                    {userBoosts.boostLuck15 > 0 && (
                      <div className='text-center justify-self-end cursor-pointer mb-3'>
                        <img
                          src={ticket15Luck}
                          alt='ticket'
                          className='w-full m-auto sm:w-9/12 md:max-w-md'
                          onClick={() => {
                            applyBoost('luck', boostShopLuckSft15);
                            props.boostModalShowToggle();
                          }}
                        />
                      </div>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
