import React from 'react';
import { useParams } from 'react-router-dom';
import BabyPandas from './BabyPandas/BabyPandas';
import PartnersStaking from './Partners/PartnersStaking';
import rightImg from './Stack.png';
import StackType from './StackType';

export default function Stack() {
  const params = useParams();

  return (
    <>
      <h2 className=' !text-4xl !mb-3 text-center !normal-case'>
        {params.subPage === 'undefined' && 'Staking pools'}
        {params.subPage === 'baby-pandas' && 'Staking pool - Couple'}
        {params.subPage === 'partners' && 'Staking pool - Partners'}
      </h2>

      {params.subPage === undefined ? (
        <>
          <div className='relative grid md:grid-cols-2 z-10 min-h-70vh'>
            <div className='md:pl-7 pt-10'>
              <StackType link='/dashboard/'>
                Stack Pandas pixel - Earn Panda Tokens
              </StackType>
              <StackType link='/dashboard/stake/baby-pandas'>
                Stack Pandas pixel Couple - Earn Baby Pandas
              </StackType>
              <StackType link='/dashboard/stake/partners'>
                Stack partners NFT - Earn Panda Tokens
              </StackType>
            </div>
            <div className='justify-self-center self-center'>
              <img src={rightImg} alt='Panda stacking' />
            </div>
          </div>
        </>
      ) : (
        <div className='md:pl-7 pt-10'>
          {params.subPage === 'baby-pandas' && <BabyPandas />}
          {params.subPage === 'partners' && <PartnersStaking />}
        </div>
      )}
    </>
  );
}
