import React, { useState } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';

import './Card.css';

export default function FertilityCard(props: any) {
  return (
    <div
      className={`rarity_card bg-ep-grey1 rounded-xl py-4 px-3 transition-all duration-200 hover:bg-slate-600 cursor-pointer mb-2 ${
        props.selected ? '!bg-ep-green' : ''
      }`}
      onClick={() => {
        props.setPanda(props.panda.nonce);
      }}
    >
      <div className='rarity_card_img'>
        <img
          className='lazy rounded-xl'
          src={`${process.env.PUBLIC_URL}/collection/${props.link}/img/${props.panda.metadata.edition}.${props.extension}`}
          alt={`${props.nftName} #${props.panda.metadata.edition}`}
        />
      </div>
      {props.rank && (
        <div className='rarity_card_rank text-center'>Rank {props.rank}</div>
      )}
      <div className='rarity_card_name text-center uppercase mt-2'>
        Fertility{' '}
        <span className='inline-block -translate-y-3px'>
          {props.panda.metadata.attributes[19].value}
        </span>
      </div>
      <div className='rarity_card_name text-center uppercase'>
        Energy{' '}
        <span className='inline-block -translate-y-3px'>
          {props.panda.metadata.attributes[18].value}
        </span>
      </div>
    </div>
  );
}
