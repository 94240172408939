import React from 'react';
import { TokenIdentifierValue } from '@elrondnetwork/erdjs/out';
import { sendTx } from 'components/TxTest/TxFunctions';
import { boostShopAdress } from 'config';

export default function SftPrices(props: any) {
  const abiUrl = 'assets/json/random_item_shop.abi.json';

  return (
    <tr key={props.index}>
      <td>{String.fromCharCode.apply(null, props.price.token)}</td>
      <td>{props.price.amount.toNumber()}</td>
      <td>
        <button
          onClick={() => {
            sendTx(
              boostShopAdress,
              'removePrice',
              [
                new TokenIdentifierValue(
                  Buffer.from(
                    String.fromCharCode.apply(null, props.price.token)
                  )
                )
              ],
              0,
              abiUrl
            );
          }}
          className='ep-btn'
        >
          Remove price
        </button>
      </td>
    </tr>
  );
}
