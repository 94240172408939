import React, { useContext, useEffect } from 'react';
import {
  transactionServices,
  useGetAccountInfo,
  useGetPendingTransactions
} from '@elrondnetwork/dapp-core';
import {
  Address,
  AddressValue,
  TokenIdentifierValue,
  U64Value
} from '@elrondnetwork/erdjs/out';
import { v4 as uuidv4 } from 'uuid';
import Card from 'components/Card/Card';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import { claimReawardsAdress, gateway } from 'config';
import { PandaContext } from 'context/PandaContext';
import Nsc1dText from './Nsc1dText';
import './PartnerStaking.css';

export default function PartnersStaking() {
  const { address } = useGetAccountInfo();
  const [refresh, setRefresh] = React.useState(0);
  const abiStakeUrl = '../../assets/json/nft_stake.abi.json';
  const [userStakes, setUserStakes] = React.useState([]) as any;
  const [txID, setTxID] = React.useState('');
  const [userSFTs, setUserSFTs] = React.useState([]) as any;
  const { pendingTransactionsArray, hasPendingTransactions } =
    useGetPendingTransactions();
  let apiError = 0;
  const { setRefreshPdtValue, refreshPdtValue } = useContext(PandaContext);
  const [userReward, setUserReward] = React.useState({}) as any;

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: txID,
    onSuccess: () => {
      setRefresh(refresh + 1);
      setRefreshPdtValue(refreshPdtValue + 1);
    },
    onFail: () => {
      console.log('fail');
    },
    onCancelled: () => {
      console.log('cancel');
    }
  });

  useEffect(() => {
    if (hasPendingTransactions) {
      setTxID(pendingTransactionsArray[0][0]);
    }
  }, [hasPendingTransactions]);

  const sftsPartners = [
    {
      collection: 'NSCITEMS-309cea',
      nonce: '29',
      collectionNonce: 'NSCITEMS-309cea-1d',
      title: 'NFT Social Club',
      text: <Nsc1dText />,
      imgUrl:
        'https://media.elrond.com/nfts/asset/bafybeibwpex7f3zixqy4wb7txasfk27rzf55tzwqbqno7id5ayjflu5fpq/item.png'
    }
  ];

  // const sftsPartnersList: Array<string> = ['NSCITEMS-309cea-1d'];
  // const sftPartnerTitle: Array<string> = ['NFT Social Club'];
  // const sftPartnerText: Array<string> = ['Texte à insérer'];
  // const sftPartnerImg: Array<string> = [
  //   'https://media.elrond.com/nfts/asset/bafybeibwpex7f3zixqy4wb7txasfk27rzf55tzwqbqno7id5ayjflu5fpq/item.png'
  // ];

  /*** SC claim ***/
  useEffect(() => {
    //Quelles stacks a l'user
    const getStacks = async () => {
      const queryGetStacks: any = await querySc(
        claimReawardsAdress,
        'getAllStakes',
        abiStakeUrl,
        [new AddressValue(new Address(address))]
      );
      setUserStakes(queryGetStacks);
    };
    getStacks();
  }, [refresh]);

  /*** Find sft not staked of user ***/
  useEffect(() => {
    sftsPartners.map((sft) => {
      const apiLink =
        gateway + 'accounts/' + address + '/nfts/' + sft.collectionNonce;
      fetchSFT(apiLink, sft.collectionNonce);
    });
  }, [refresh]);

  const fetchSFT = (link: string, sft: string) => {
    fetch(link)
      .then((response) => {
        const userSFTJson = response.json();
        return userSFTJson;
      })
      .then((userSFTJson) => {
        const userSFTsSaved = userSFTs;
        userSFTJson.balance != undefined
          ? (userSFTsSaved[sft] = userSFTJson.balance)
          : (userSFTsSaved[sft] = 0);
        setUserSFTs(''); //Fix a bug that doesn't update the state
        setUserSFTs(userSFTsSaved);
      })
      .catch(function (error) {
        console.log(error);

        apiError++;
        if (apiError < 3) {
          fetchSFT(link, sft);
        } else {
          apiError = 0;
          console.log(
            // eslint-disable-next-line quotes
            "Il y a eu un problème avec l'opération fetch: " + error.message
          );
        }
      });
  };

  //Get rewards
  useEffect(() => {
    getAllReward();

    const rewardsInterval = setInterval(() => {
      //Get rewards every 10s
      getAllReward();
    }, 10000);

    return () => {
      clearInterval(rewardsInterval);
    };
  }, [refresh, userStakes]);

  const getAllReward = async () => {
    sftsPartners.map(async (sft) => {
      const queryGetRewards: any = await querySc(
        claimReawardsAdress,
        'getReward',
        abiStakeUrl,
        [
          new AddressValue(new Address(address)),
          new TokenIdentifierValue(Buffer.from(sft.collection)),
          new U64Value(sft.nonce)
        ]
      );
      if (typeof queryGetRewards != 'string') {
        const userRewardSaved: any = userReward;
        userRewardSaved[sft.collectionNonce] = (
          queryGetRewards.toNumber() * Math.pow(10, -18)
        ).toFixed(2);
        setUserReward(userRewardSaved);
      }
    });
  };

  //Get component txId
  const getTxId = (tx: any) => {
    setTxID(tx);
  };

  return sftsPartners.map((sft, key) => {
    return (
      <div key={key} className='relative grid md:grid-cols-2 z-10'>
        <div className='md:pl-7'>
          <h2 className=' !font-semibold !text-4xl !mb-5 !normal-case inline-block mr-5'>
            {sft.title}
          </h2>
          <button
            className={`ep-btn !text-md mb-1 px-7 inline-block -translate-y-1 ${
              userReward[sft.collectionNonce] >= 1
                ? 'bg-ep-green hover:bg-ep-green2 cursor-pointer'
                : '!bg-gray-600 cursor-not-allowed'
            }`}
            onClick={async () => {
              if (userReward[sft.collectionNonce] >= 1) {
                const claimRewards: any = await sendTx(
                  claimReawardsAdress,
                  'claim',
                  [
                    new TokenIdentifierValue(Buffer.from(sft.collection)),
                    new U64Value(sft.nonce)
                  ],
                  0,
                  abiStakeUrl
                );
                setTxID(claimRewards.sessionId);
              }
            }}
          >
            Claim&nbsp;
            {userReward[sft.collectionNonce]}
            &nbsp;
            {userReward[sft.collectionNonce] > 0 && 'token'}
            {userReward[sft.collectionNonce] > 1 && 's'}
          </button>
          <div className='max-w-7xl m-auto grid grid-cols-2 gap-3 sm:gap-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
            {userStakes &&
            userStakes.filter(
              (e: any) =>
                String.fromCharCode.apply(null, e.collection_id) ===
                sft.collection
            ).length ? (
              <Card
                key={uuidv4()}
                id={sft.nonce}
                link={sft.imgUrl}
                nftName={sft.title}
                collection={sft.collection}
                extension='png'
                stakeInfo='true'
                staked='true'
                sendTxId={getTxId}
                panda={{
                  nonce: sft.nonce,
                  collection: sft.collection
                }}
                sftPartner={true}
              />
            ) : (
              ''
            )}
            {userSFTs && userSFTs[sft.collectionNonce] > 0
              ? Array(userSFTs[sft.collectionNonce]).fill(
                  <Card
                    key={uuidv4()}
                    id={sft.nonce}
                    link={sft.imgUrl}
                    nftName={sft.title}
                    collection={sft.collection}
                    extension='png'
                    stakeInfo={
                      userStakes.filter(
                        (e: any) =>
                          String.fromCharCode.apply(null, e.collection_id) ===
                          sft.collectionNonce
                      ).length
                        ? false
                        : true
                    }
                    staked='false'
                    sendTxId={getTxId}
                    panda={{
                      nonce: sft.nonce,
                      collection: sft.collection
                    }}
                    sftPartner={true}
                  />
                )
              : ''}

            {userSFTs &&
            userSFTs[sft.collectionNonce] == 0 &&
            userStakes &&
            !userStakes.filter(
              (e: any) =>
                String.fromCharCode.apply(null, e.collection_id) ===
                sft.collection
            ).length ? (
              <p>You don&apos;t have any {sft.title} in your wallet</p>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='ep-partner-text md:ml-10 max-w-xl mt-20'>
          {sft.text}
        </div>
      </div>
    );
  });
}
