import React from 'react';
import { DappUI, DappProvider } from '@elrondnetwork/dapp-core';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import PandaContaxtProvider from 'context/PandaContext';
import PageNotFound from 'pages/PageNotFound';
import routes from 'routes';
import '@elrondnetwork/dapp-core/dist/index.css';
// eslint-disable-next-line prefer-const
export let environment = 'mainnet';

const { TransactionsToastList, SignTransactionsModals, NotificationModal } =
  DappUI;

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
      >
        <PandaContaxtProvider>
          <Layout>
            <TransactionsToastList className='z-50 ' />
            <NotificationModal />
            <SignTransactionsModals className='z-50 ' />
            <Routes>
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </PandaContaxtProvider>
      </DappProvider>
    </Router>
  );
};

export default App;
