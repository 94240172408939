import React, { useEffect, useState, useRef } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
  BooleanValue,
  BytesValue,
  BigUIntValue,
  Balance,
  Token,
  ProxyProvider,
  TokenIdentifierValue,
  Address,
  AddressValue
} from '@elrondnetwork/erdjs/out';
import { useNavigate } from 'react-router-dom';
import { environment } from 'App';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import { sendTx, querySc } from 'components/TxTest/TxFunctions';
import {
  boostShopLuckAdress as boostShopAdress,
  pandaToken,
  gateway as elrondApi
} from 'config';
import './Boostshop.css';
import SetBuyableSft from './SetBuyableSft/BuyableSft';
import SftPrices from './SftPrices/SftPrices';

export default function BoostShopLuck() {
  const [sftCollectionTokenIsSet, setSftCollectionTokenIsSet] = useState(false);
  const [sftCollectionToken, setSftCollectionToken] = useState() as Array<any>;
  const [isBSactivated, setIsBSactivated] = useState(false);
  const [scSft, setScSft] = useState(undefined) as Array<any>;
  const [sftPrices, setSftPrices] = useState(undefined) as Array<any>;
  const navigate = useNavigate();
  const allowlist: Array<any> = ['all'];
  const { address } = useGetAccountInfo();

  const issueTokenDisplayName: any = useRef(null);
  const issueTokenTicker: any = useRef(null);
  const createNewSftAmount: any = useRef(null);
  const createNewSftName: any = useRef(null);
  const createNewSftAttr: any = useRef(null);
  const createNewSftFullAmount: any = useRef(null);
  const createNewSftFullName: any = useRef(null);
  const createNewSftFullAttr: any = useRef(null);
  const createNewSftFullRoyalties: any = useRef(null);
  const createNewSftFullHash: any = useRef(null);
  const createNewSftFullUri: any = useRef(null);
  const setBuyableSftNonce: any = useRef(null);
  const setBuyableSftWeight: any = useRef(null);
  const setBuyableSft: any = useRef([]);
  const setPriceIdentifier: any = useRef();
  const setPricePrice: any = useRef();
  const abiUrl = 'assets/json/random_item_shop.abi.json';

  //Qui peut accéder à la page
  if (!allowlist.includes('all')) {
    if (allowlist.includes(address)) navigate('/dashboard'); //on route les utilisateurs sans permission vers le dashboard
  }

  useEffect(() => {
    //Est-ce que le boostshop est activé ?
    const fetchBSSTate = async () => {
      const queryBSstate: any = await querySc(
        boostShopAdress,
        'isActive',
        abiUrl
      );
      if (typeof queryBSstate === 'boolean') {
        setIsBSactivated(queryBSstate);
      } else {
        console.log(queryBSstate);
      }
    };
    fetchBSSTate();
  }, []);

  useEffect(() => {
    //Est-ce que la collection est créée ?
    const fetchCollectionIsSet = async () => {
      const queryCollectionIsSet: any = await querySc(
        boostShopAdress,
        'sftCollectionTokenIsSet',
        abiUrl
      );
      if (typeof queryCollectionIsSet === 'boolean') {
        setSftCollectionTokenIsSet(queryCollectionIsSet);
        // console.log(queryCollectionIsSet);
      } else {
        console.log(queryCollectionIsSet);
      }
    };
    fetchCollectionIsSet();
  }, []);

  useEffect(() => {
    //Quelles sont les collections de sft du contrat ?
    const getSftCollectionToken = async () => {
      const queryBSCollec: any = await querySc(
        boostShopAdress,
        'getSftCollectionToken',
        abiUrl
      );

      setSftCollectionToken(String.fromCharCode.apply(null, queryBSCollec));
      console.log(String.fromCharCode.apply(null, queryBSCollec));
    };
    getSftCollectionToken();
  }, []);

  useEffect(() => {
    if (sftCollectionToken) {
      // //Quelles sont les sft du contrat
      const sftOfSc =
        elrondApi + 'collections/' + sftCollectionToken + '/nfts?size=10000';
      fetch(sftOfSc)
        .then((response) => {
          const sftOfScJson = response.json();
          return sftOfScJson;
        })
        .then((sftOfScJson) => {
          console.log(sftOfScJson);
          getBuyableSfts(sftOfScJson);
        });
    }

    const getBuyableSfts = async (sftOfScJson: Array<any>) => {
      //Quelles sont les sft achetables ?
      const queryBuyableSft: any = await querySc(
        boostShopAdress,
        'getBuyableSfts',
        abiUrl
      );

      sftOfScJson.map((sft: any) => {
        //On rassemble les arrays
        queryBuyableSft.map((sftBuyable: any) => {
          if (sftBuyable.sft_nonce.toNumber() === sft.nonce) {
            sft.weight = sftBuyable.weight.toNumber();
            sft.buyable = true;
          }
        });
        if (sft.buyable === undefined) {
          sft.buyable = false;
        }
      });
      setScSft(sftOfScJson);

      // console.log(sftOfScJson);
    };
  }, [sftCollectionToken]);

  useEffect(() => {
    //On récupère les prix des sft
    const getPrices = async () => {
      const querySftPrices: any = await querySc(
        boostShopAdress,
        'getPrices',
        abiUrl
      );
      setSftPrices(querySftPrices);

      console.log(querySftPrices);
    };
    getPrices();
  }, []);

  function getActiveTx() {
    // const activeTx = useGetActiveTransactionsStatus();
    // console.log(activeTx);
  }

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='ep-boostshop bg-ep-blue text-ep-slate'>
        <div className='ep-container h-full'>
          <h1 className='text-5xl font-bold text-center my-5'>
            BoostShop Luck - Administration
          </h1>

          {sftCollectionTokenIsSet && (
            <div>
              <h3 className='text-2xl underline mb-3'>
                Activation / Désaction
              </h3>
              <span
                className={`${
                  isBSactivated ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {isBSactivated && isBSactivated
                  ? 'Boost shop activé '
                  : 'Boost shop désactivé '}
              </span>
              <button
                className='ep-btn'
                onClick={() =>
                  sendTx(
                    boostShopAdress,
                    'setActive',
                    [new BooleanValue(!isBSactivated)],
                    0,
                    abiUrl
                  )
                }
              >
                {isBSactivated && isBSactivated ? 'Désctiver' : 'Activer'}
              </button>
            </div>
          )}

          {!sftCollectionTokenIsSet && (
            <div>
              <h3 className='text-2xl underline mb-3 mt-5'>
                Issue sft collection
              </h3>
              <table className='flex items-center gap-x-3 mt-5'>
                <tr>
                  <td>
                    <input
                      ref={issueTokenDisplayName}
                      type='text'
                      name='issueTokenDisplayName'
                      placeholder='Token display name'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      ref={issueTokenTicker}
                      type='text'
                      name='issueTokenTicker'
                      placeholder='Token ticker'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className='ep-btn'
                      onClick={() =>
                        sendTx(
                          boostShopAdress,
                          'issueSftCollection',
                          [
                            BytesValue.fromUTF8(
                              issueTokenDisplayName.current.value
                            ),
                            BytesValue.fromUTF8(issueTokenTicker.current.value)
                          ],
                          0.05,
                          abiUrl,
                          [],
                          '',
                          500000000
                        )
                      }
                    >
                      Issue Sft Collection
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          )}

          {sftCollectionTokenIsSet && (
            <>
              <div>
                <h3 className='text-2xl underline mb-3 mt-5'>Create new sft</h3>
                <table className='mt-5'>
                  <tr className='flex items-center gap-x-3 '>
                    <td>
                      <input
                        ref={createNewSftAmount}
                        type='number'
                        name='createNewSftAmount'
                        placeholder='Amount'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftName}
                        type='text'
                        name='createNewSftName'
                        placeholder='Name'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftAttr}
                        type='text'
                        name='createNewSftAttributes'
                        placeholder='Attributes'
                      />
                    </td>
                    <td>
                      <button
                        className='ep-btn'
                        onClick={() =>
                          sendTx(
                            boostShopAdress,
                            'createNewSft',
                            [
                              new BigUIntValue(
                                new Balance(
                                  new Token(),
                                  0,
                                  createNewSftAmount.current.value
                                ).valueOf()
                              ),
                              BytesValue.fromUTF8(
                                createNewSftName.current.value
                              ),
                              BytesValue.fromUTF8(
                                createNewSftAttr.current.value
                              )
                            ],
                            0,
                            abiUrl
                          )
                        }
                      >
                        Create New Sft
                      </button>
                    </td>
                  </tr>
                </table>
              </div>

              <div>
                <h3 className='text-2xl underline mb-3 mt-5'>
                  Create new sft full
                </h3>
                <table className='mt-5'>
                  <tr className='flex gap-3 flex-wrap items-center'>
                    <td>
                      <input
                        ref={createNewSftFullAmount}
                        type='number'
                        name='createNewSftFullAmount'
                        placeholder='Amount'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftFullName}
                        type='text'
                        name='createNewSftFullName'
                        placeholder='Name'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftFullRoyalties}
                        type='text'
                        name='createNewSftFullRoyalties'
                        placeholder='Royalties (en centième de pourcentage)'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftFullHash}
                        type='text'
                        name='createNewSftFullHash'
                        placeholder='Hash'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftFullAttr}
                        type='text'
                        name='createNewSftFullAttr'
                        placeholder='Attributes'
                      />
                    </td>
                    <td>
                      <input
                        ref={createNewSftFullUri}
                        type='text'
                        name='createNewSftFullUri'
                        placeholder='Uri'
                      />
                    </td>
                    <td>
                      <button
                        className='ep-btn'
                        onClick={() =>
                          sendTx(
                            boostShopAdress,
                            'createNewSftFull',
                            [
                              new BigUIntValue(
                                new Balance(
                                  new Token(),
                                  0,
                                  createNewSftFullAmount.current.value
                                ).valueOf()
                              ),
                              BytesValue.fromUTF8(
                                createNewSftFullName.current.value
                              ),
                              new BigUIntValue(
                                new Balance(
                                  new Token(),
                                  0,
                                  createNewSftFullRoyalties.current.value
                                ).valueOf()
                              ),
                              BytesValue.fromUTF8(
                                createNewSftFullHash.current.value
                              ),
                              BytesValue.fromUTF8(
                                createNewSftFullAttr.current.value
                              ),
                              BytesValue.fromUTF8(
                                createNewSftFullUri.current.value
                              )
                            ],
                            0,
                            abiUrl
                          )
                        }
                      >
                        Create New Sft
                      </button>
                    </td>
                  </tr>
                </table>
              </div>

              <div>
                <h3 className='text-2xl underline mb-3 mt-5'>
                  Set / remove buyable SFT
                </h3>
                <table className='mt-5 pb-5 boostshoop_table'>
                  <thead>
                    <tr>
                      <td>Identifiant</td>
                      <td>Nom</td>
                      <td>Weight</td>
                      <td>Status</td>
                      <td>Set/remove Buyable</td>
                    </tr>
                  </thead>
                  <tbody ref={setBuyableSft}>
                    {scSft &&
                      scSft.map((sft: any, index: any) => (
                        <SetBuyableSft
                          key={index}
                          sft={sft}
                          abi={abiUrl}
                          sc={boostShopAdress}
                        />
                      ))}
                  </tbody>
                </table>
              </div>

              <div className='pb-5'>
                <h3 className='text-2xl underline mb-3 mt-5'>Prices</h3>
                <table className=' boostshoop_table'>
                  <thead>
                    <tr>
                      <td>Identifiant</td>
                      <td>Prix</td>
                      <td>setPrice</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input ref={setPriceIdentifier} type='text' />
                      </td>
                      <td>
                        <input ref={setPricePrice} type='number' />
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            sendTx(
                              boostShopAdress,
                              'setPrice',
                              [
                                new TokenIdentifierValue(
                                  Buffer.from(setPriceIdentifier.current.value)
                                ),
                                new BigUIntValue(
                                  new Balance(
                                    new Token(),
                                    0,
                                    setPricePrice.current.value
                                  ).valueOf()
                                )
                              ],
                              0,
                              abiUrl
                            );
                          }}
                          className='ep-btn'
                        >
                          Set price
                        </button>
                      </td>
                    </tr>
                    {sftPrices &&
                      sftPrices.map((price: any, index: any) => (
                        <SftPrices key={index} price={price} />
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
