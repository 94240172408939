import React from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
  boostShopLuckSft,
  boostShopSft,
  gateway,
  boostShopSft15,
  boostShopSft30,
  boostShopSft40,
  boostShopSft50,
  boostShopLuckSft15,
  boostShopLuckSft30,
  boostShopLuckSft40,
  boostShopLuckSft50
} from 'config';
import type { userBoosts } from './PandaContext';

export async function getUserBoosts(address: string) {
  const userBoostsValue: userBoosts = {
    boostTime15: 0,
    boostTime30: 0,
    boostTime40: 0,
    boostTime50: 0,
    boostLuck15: 0,
    boostLuck30: 0,
    boostLuck40: 0,
    boostLuck50: 0
  };

  const apiBoostTime =
    gateway + 'accounts/' + address + '/nfts?collection=' + boostShopSft;

  const apiBoostTimeFetch = await fetch(apiBoostTime)
    .then((response) => {
      const apiBoostResponse = response.json();
      return apiBoostResponse;
    })
    .then((apiBoostResponse) => {
      apiBoostResponse.map((item: { balance: number; identifier: any }) => {
        if (item.identifier === boostShopSft15) {
          userBoostsValue.boostTime15 = item.balance;
        } else if (item.identifier === boostShopSft30) {
          userBoostsValue.boostTime30 = item.balance;
        } else if (item.identifier === boostShopSft40) {
          userBoostsValue.boostTime40 = item.balance;
        } else if (item.identifier === boostShopSft50) {
          userBoostsValue.boostTime50 = item.balance;
        }
      });
    })
    .catch(function (error) {
      console.log(
        // eslint-disable-next-line quotes
        "Il y a eu un problème avec l'opération fetch: " + error.message
      );
    });

  //Luck
  const apiBoostLuck =
    gateway + 'accounts/' + address + '/nfts?collection=' + boostShopLuckSft;

  const apiBoostLuckFetch = await fetch(apiBoostLuck)
    .then((response) => {
      const apiBoostResponse = response.json();
      return apiBoostResponse;
    })
    .then((apiBoostResponse) => {
      apiBoostResponse.map((item: any) => {
        if (item.identifier === boostShopLuckSft15) {
          userBoostsValue.boostLuck15 = item.balance;
        } else if (item.identifier === boostShopLuckSft30) {
          userBoostsValue.boostLuck30 = item.balance;
        } else if (item.identifier === boostShopLuckSft40) {
          userBoostsValue.boostLuck40 = item.balance;
        } else if (item.identifier === boostShopLuckSft50) {
          userBoostsValue.boostLuck50 = item.balance;
        }
      });
    })
    .catch(function (error) {
      console.log(
        // eslint-disable-next-line quotes
        "Il y a eu un problème avec l'opération fetch: " + error.message
      );
    });

  return userBoostsValue;
}
