import React from 'react';
import { NavLink } from 'react-router-dom';

export default function StackType(props: any) {
  return (
    <NavLink to={props.link}>
      <div className='relative border py-9 px-8 mb-8 rounded-3xl flex shadow shadow-black bg-ep-blue z-10 hover:bg-ep-blue2'>
        <p className='flex-1 font-semibold text-xl'>{props.children}</p>
        <div className='flex-initial'>
          <button className='ep-btn-core text-ep-slate bg-ep-green cursor-pointer px-5 '>
            Stake
          </button>
        </div>
      </div>
    </NavLink>
  );
}
