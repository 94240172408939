import React from 'react';
import './TeamMember.css';

export default function TeamMember2(props) {
  return (
    <div className='mb-10 sm:flex sm:flex-col sm:mb-5 sm:w-2/4 sm:even:pl-4 sm:odd:pr-4 md:w-52 md:!p-0'>
      <div className='sm:order-1 text-center'>
        <h3 className='mb-1 text-3xl sm:mt-3'>{props.name}</h3>
        <p className=''>{props.children}</p>
      </div>
      <div className=''>
        <img
          className='rounded-3xl border border-slate-500'
          src={process.env.PUBLIC_URL + `/assets/img/members/${props.name}.png`}
          alt={`Picture of ${props.name}`}
        />
      </div>
    </div>
  );
}
