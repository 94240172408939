import React from 'react';
import './SocialLink.css';
import discordIcon from './Discord_icon.png';
import instagramIcon from './Instagram_icon.png';
import telegramIcon from './Telegram_icon.png';
import twitterIcon from './twitter_icon.png';

export default function SocialLink() {
  return (
    <div className='absolute bottom-0 grid grid-cols-2 gap-y-3 gap-x-10 mb-4 md:grid-cols-4 self-center  z-10'>
      <a
        rel='noreferrer'
        className='w-16 self-center group'
        href='https://discord.com/invite/XhyYvcAC4V'
        target='_blank'
      >
        <img
          className='group-hover:scale-105 group-hover:brightness-75 group-hover:-translate-x-2 group-hover:-translate-y-4 transition-all duration-150'
          src={discordIcon}
          alt='Discord Icon'
        />
      </a>
      <a
        rel='noreferrer'
        className='w-16 self-center group'
        href='https://twitter.com/ElrondPandas'
        target='_blank'
      >
        <img
          className='group-hover:scale-105 group-hover:brightness-75 group-hover:translate-x-2 group-hover:-translate-y-4 transition-all duration-150'
          src={twitterIcon}
          alt='Twitter Icon'
        />
      </a>
      <a
        rel='noreferrer'
        className='w-16 self-center group'
        href='https://www.instagram.com/elrondpandas/'
        target='_blank'
      >
        <img
          className='group-hover:scale-105 group-hover:brightness-75 group-hover:-translate-x-2 group-hover:-translate-y-4 transition-all duration-150'
          src={instagramIcon}
          alt='Instagram Icon'
        />
      </a>
      <a
        rel='noreferrer'
        className='w-16 self-center group'
        href='https://t.me/+M9wCJO-bnxE1MDJk'
        target='_blank'
      >
        <img
          className='group-hover:scale-105 group-hover:brightness-75 group-hover:translate-x-2 group-hover:-translate-y-4 transition-all duration-150'
          src={telegramIcon}
          alt='Telegram Icon'
        />
      </a>
    </div>
  );
}
