import React, { useEffect, useState, useRef } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
  AddressValue,
  Address,
  AddressType,
  TypedValue,
  BooleanValue,
  TokenIdentifierValue,
  U64Value,
  BigUIntValue,
  Balance,
  Token
} from '@elrondnetwork/erdjs/out';
import { useNavigate } from 'react-router-dom';
import NavbarPanda from 'components/NavbarPanda/NavbarPanda';
import { sendTx, querySc } from 'components/TxTest/TxFunctions';
import { claimReawardsAdress } from 'config';
import './ClaimRewards.css';
import StackItem from './StackItem';

export default function ClaimRewards() {
  const abiUrl = 'assets/json/nft_stake.abi.json';
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();
  const [isSCactivated, setIsSCactivated] = useState(false);
  const [stackSettings, setStackSettings] = useState([]) as any;
  const [currentMinClaimTimespan, setCurrentMinClaimTimespan] = useState(0);
  const [minCurrentClaimAmount, setMinCurrentClaimAmount] = useState(0);
  const [unitMultiplier, setUnitMultiplier] = useState(0);
  const setStackCollectionId: any = useRef(null);
  const setStackTimespan: any = useRef(null);
  const setStackNonce: any = useRef(null);
  const setRewardTokenId: any = useRef(null);
  const MinClaimTimespan: any = useRef(null);
  const minClaimAmount: any = useRef(null);
  const multiplier: any = useRef(null);

  useEffect(() => {
    //est-qyue l'utilisateur est admin
    const isAdmin = async () => {
      const queryIsAdmin: any = await querySc(
        claimReawardsAdress,
        'isAdmin',
        abiUrl,
        [new AddressValue(new Address(address))]
      );
      if (typeof queryIsAdmin === 'boolean') {
        // !queryIsAdmin && navigate('/dashboard');
      } else {
        console.log(queryIsAdmin);
      }
    };
    isAdmin();
  }, []);

  useEffect(() => {
    //Est-ce que le sc de claim est activé ?
    const fetchSCSTate = async () => {
      const queryBSstate: any = await querySc(
        claimReawardsAdress,
        'isActive',
        abiUrl
      );
      if (typeof queryBSstate === 'boolean') {
        setIsSCactivated(queryBSstate);
      } else {
        console.log(queryBSstate);
      }
    };
    fetchSCSTate();
  }, []);

  useEffect(() => {
    //Retourne tout les stacks settings déjà créés
    const fetchSCSStakes = async () => {
      const querySCStack: any = await querySc(
        claimReawardsAdress,
        'getAllStakeSettings',
        abiUrl
      );
      setStackSettings(querySCStack);
      console.log(querySCStack);
    };
    fetchSCSStakes();
  }, []);

  useEffect(() => {
    //Get min_claim_timespan_delay
    const fetchMinClaimTimespan = async () => {
      const queryMinClaimTimespan: any = await querySc(
        claimReawardsAdress,
        'min_claim_timespan_delay',
        abiUrl
      );
      setCurrentMinClaimTimespan(queryMinClaimTimespan.toNumber());
      console.log(queryMinClaimTimespan.toNumber());
    };
    fetchMinClaimTimespan();
  }, []);

  useEffect(() => {
    //Get min_claim_amount
    const fetchMinClaimAmount = async () => {
      const queryMinClaimAmount: any = await querySc(
        claimReawardsAdress,
        'min_claim_amount',
        abiUrl
      );
      setMinCurrentClaimAmount(queryMinClaimAmount.toNumber());
      console.log(queryMinClaimAmount.toNumber());
    };
    fetchMinClaimAmount();
  }, []);

  useEffect(() => {
    //get unit_multiplier
    const fetchUnitMultiplier = async () => {
      const queryUnitMultiplier: any = await querySc(
        claimReawardsAdress,
        'unit_multiplier',
        abiUrl
      );
      setUnitMultiplier(queryUnitMultiplier.toNumber());
      console.log(queryUnitMultiplier.toNumber());
    };
    fetchUnitMultiplier();
  }, []);

  const test = () => {
    console.log(setStackTimespan.current.value);
    const big = new BigUIntValue(
      new Balance(new Token(), 0, setStackTimespan.current.value).valueOf()
    );
    console.log(big);
  };

  const setStackArgs = (nonceValue: any) => {
    console.log(nonceValue);

    if (nonceValue != '') {
      return [
        new TokenIdentifierValue(
          Buffer.from(setStackCollectionId.current.value)
        ),
        new BigUIntValue(
          new Balance(new Token(), 0, setStackTimespan.current.value).valueOf()
        ),
        new BigUIntValue(
          new Balance(new Token(), 0, setStackNonce.current.value).valueOf()
        )
      ];
    } else {
      return [
        new TokenIdentifierValue(
          Buffer.from(setStackCollectionId.current.value)
        ),
        new BigUIntValue(
          new Balance(new Token(), 0, setStackTimespan.current.value).valueOf()
        )
      ];
    }
  };

  return (
    <>
      <div className='ep-container text-ep-slate'>
        <NavbarPanda />
      </div>
      <div className='ep-claim bg-ep-blue text-ep-slate pb-10'>
        <div className='ep-container h-full'>
          <h1 className='text-5xl font-bold text-center my-5'>
            SC de claim - Administration
          </h1>

          {true && (
            <div>
              <h3 className='text-2xl underline mb-3'>
                Activation / Désaction
              </h3>
              <span
                className={`${
                  isSCactivated ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {isSCactivated && isSCactivated
                  ? 'SC de claim activé '
                  : 'SC de claim désactivé '}
              </span>
              <button
                className='ep-btn'
                onClick={() =>
                  sendTx(
                    claimReawardsAdress,
                    'setActive',
                    [new BooleanValue(!isSCactivated)],
                    0,
                    abiUrl
                  )
                }
              >
                {isSCactivated && isSCactivated ? 'Désctiver' : 'Activer'}
              </button>
            </div>
          )}

          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>
              Set reward token id
            </h3>
            <table className='flex items-center gap-x-3 mt-5'>
              <tr>
                <td>
                  <input
                    ref={setRewardTokenId}
                    type='text'
                    name='token_id'
                    placeholder='Token Id'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        claimReawardsAdress,
                        'setRewardTokenId',
                        [
                          new TokenIdentifierValue(
                            Buffer.from(setRewardTokenId.current.value)
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Set
                  </button>
                </td>
              </tr>
            </table>
          </div>

          {true && (
            <div>
              <h3 className='text-2xl underline mb-3 mt-5'>
                Set Stake Settings
              </h3>
              <table className='flex items-center gap-x-3 mt-5'>
                <tr>
                  <td>
                    <input
                      ref={setStackCollectionId}
                      type='text'
                      name='collection_id'
                      placeholder='Collection id'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      ref={setStackTimespan}
                      type='number'
                      name='timespan_per_unit'
                      placeholder='Timespan per unit'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      ref={setStackNonce}
                      type='text'
                      name='opt_nonce'
                      placeholder='Optionnal nonce'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className='ep-btn'
                      onClick={() => {
                        sendTx(
                          claimReawardsAdress,
                          'setStakeSettings',
                          setStackArgs(setStackNonce.current.value),
                          0,
                          abiUrl
                        );
                      }}
                    >
                      Set Stake Settings
                    </button>
                  </td>
                </tr>
              </table>
              <p>
                <b>Timespan per unit</b> : Générera 1 de la plus petite unité du
                token * le reward multiplier <br />
              </p>
              <table>
                <tr className='flex items-center gap-x-3 mt-5'>
                  <td>
                    <input
                      ref={multiplier}
                      type='number'
                      name='multiplier'
                      placeholder='Multiplier'
                    />
                  </td>
                  <td>
                    <button
                      className='ep-btn'
                      onClick={() => {
                        sendTx(
                          claimReawardsAdress,
                          'setUnitMultiplier',
                          [
                            new BigUIntValue(
                              new Balance(
                                new Token(),
                                0,
                                multiplier.current.value
                              ).valueOf()
                            )
                          ],
                          0,
                          abiUrl
                        );
                      }}
                    >
                      Set Reward Multiplier
                    </button>
                  </td>
                </tr>
              </table>
              <p>Actual : {unitMultiplier}</p>
            </div>
          )}

          {stackSettings && (
            <div>
              <h3 className='text-2xl underline mb-3 mt-5'>Stacks List</h3>
              <table className='claim_table mt-5'>
                <thead>
                  <tr>
                    <td>Collection id</td>
                    <td>Timespan</td>
                    <td>Nonce</td>
                    <td>Remove stack</td>
                  </tr>
                </thead>
                <tbody>
                  {stackSettings.map((stack: any, index: number) => (
                    <StackItem key={index} stack={stack} />
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>
              Set Min Claim Timespan Delay
            </h3>
            <table className='flex items-center gap-x-3 mt-5'>
              <tr>
                <td>
                  <p className='mr-3'>
                    Actual :&nbsp;
                    {currentMinClaimTimespan
                      ? currentMinClaimTimespan + ' seconds'
                      : 'Not set'}
                  </p>
                </td>
                <td>
                  <input
                    ref={MinClaimTimespan}
                    type='text'
                    name='MinClaimTimespan'
                    placeholder='Min Claim Delay'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        claimReawardsAdress,
                        'setMinClaimTimespanDelay',
                        [
                          new BigUIntValue(
                            new Balance(
                              new Token(),
                              0,
                              MinClaimTimespan.current.value
                            ).valueOf()
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Set
                  </button>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <h3 className='text-2xl underline mb-3 mt-5'>
              Set claim min amount
            </h3>
            <table className='flex items-center gap-x-3 mt-5'>
              <tr>
                <td>
                  <p className='mr-3'>
                    Actual :&nbsp;
                    {minCurrentClaimAmount
                      ? minCurrentClaimAmount + ' tokens'
                      : 'Not set'}
                  </p>
                </td>
                <td>
                  <input
                    ref={minClaimAmount}
                    type='text'
                    name='min_amount'
                    placeholder='Min claim amount'
                    className='mr-3'
                  />
                </td>
                <td>
                  <button
                    className='ep-btn'
                    onClick={() =>
                      sendTx(
                        claimReawardsAdress,
                        'setClaimAmount',
                        [
                          new BigUIntValue(
                            new Balance(
                              new Token(),
                              0,
                              minClaimAmount.current.value
                            ).valueOf()
                          )
                        ],
                        0,
                        abiUrl
                      )
                    }
                  >
                    Set
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
