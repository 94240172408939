import React, { useEffect, useRef, useState } from 'react';
import { Address, AddressValue, U32Value } from '@elrondnetwork/erdjs/out';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';

export default function CoupleStaked(props: any) {
  // console.log(props.coupleInfo);

  const [remainingTime, setRemainingTime] = useState('');
  const [hasBaby, setHasBaby] = useState('');
  // const mountedRef = useRef(true); //pour vérifier si le composant est toujours monté

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime(); //Timestamps courant
      const distance =
        props.coupleInfo.unstake_timestamp.toNumber() * 1000 - now; //Timestamps restant

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setRemainingTime(
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's'
        );
      } else if (hasBaby == '') {
        clearInterval(interval);
        setHasBaby('toReveal');
      }
    }, 1000);
    return () => {
      // console.log('unmounting');
      // mountedRef.current = false;
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='flex flex-col	md:flex-row md:inline-flex p-3 gap-7 mb-7  border-8 rounded-3xl border-ep-grey3 relative z-10 items-center	'>
      <div className=''>
        <img
          className='lazy rounded-xl md:w-40'
          src={`${
            process.env.PUBLIC_URL
          }/collection/ElrondPandas/img/${props.coupleInfo.female_nonce.toNumber()}.png`}
          alt={`Elrond Pandas #${props.coupleInfo.female_nonce.toNumber()}`}
        />
      </div>
      <div>
        <img
          className='lazy rounded-xl md:w-40'
          src={`${
            process.env.PUBLIC_URL
          }/collection/ElrondPandas/img/${props.coupleInfo.male_nonce.toNumber()}.png`}
          alt={`Elrond Pandas #${props.coupleInfo.male_nonce.toNumber()}`}
        />
      </div>
      <div className='justify-self-center text-center'>
        <p>
          {hasBaby == '' ? (
            remainingTime
          ) : hasBaby == 'toReveal' ? (
            <button
              className='ep-btn'
              onClick={() => {
                const claimBaby: any = sendTx(
                  props.scAddress,
                  'claimIfBaby',
                  [new U32Value(props.coupleIndex + 1)],
                  0,
                  props.abiUrl,
                  [],
                  props.userAddress,
                  8000000
                );
                props.setTxType('claimBaby');
                props.setTxID(claimBaby.sessionId);
              }}
            >
              See the result
            </button>
          ) : (
            'No baby'
          )}
        </p>
        <p>
          Chance of getting a baby : {props.coupleInfo.drop_rate.toNumber()}%
        </p>
        <p>
          {'🤍'.repeat(Math.round(props.coupleInfo.drop_rate.toNumber() / 20))}
        </p>
      </div>
      <div>
        <button
          className='ep-btn '
          onClick={() => {
            props.setCoupleIdToBoost(props.coupleIndex + 1);
            props.boostModalShowToggle();
          }}
        >
          Use boost
        </button>
      </div>
      <div>
        <button
          className='ep-btn'
          onClick={() => {
            const retrieveNfts: any = sendTx(
              props.scAddress,
              'retrieveNfts',
              [new U32Value(props.coupleIndex + 1)],
              0,
              props.abiUrl,
              [],
              props.userAddress,
              50000000
            );
            props.setTxType('unstake');
            props.setTxID(retrieveNfts.sessionId);
          }}
        >
          Unstake
        </button>
      </div>
    </div>
  );
}
