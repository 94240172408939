import React, { useState, useEffect, useRef, useContext } from 'react';
import { logout, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { PandaContext } from 'context/PandaContext';
import routes, { routeNames } from 'routes';
import pandaCoin from '../../assets/img/panda_coin.png';
import imgBurger from './burger.png';
import imgLogo from './logo.png';
import Navitem from './Navitem/Navitem';

export default function NavbarPanda() {
  const [toggleMenu, SetToggleMenu] = useState(false);
  const [heightEl, setHeightEl] = useState();
  const refMenuHeight = useRef();
  const navigate = useNavigate();
  const { pdtValue } = useContext(PandaContext);

  const toggleNav = () => {
    SetToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    setHeightEl(`${refMenuHeight.current.scrollHeight}px`);
  });

  const resetMenuHeight = () => {
    setHeightEl(null);
  };

  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <nav className='relative sm:flex'>
      <div className='flex py-5 items-center cursor-pointer'>
        <img
          onClick={() => {
            navigate('/');
          }}
          src={imgLogo}
          alt=''
          className='w-5 sm:w-7.6 mr-2'
        />
        <h1
          onClick={() => {
            navigate('/');
          }}
          className='font-bold uppercase text-2xl sm:text-4xl sm:shrink'
        >
          Elrond Pandas
        </h1>
      </div>
      <ul
        ref={refMenuHeight}
        className={
          'ep-menu z-50 transition-height duration-1000 overflow-hidden flex flex-col items-center absolute left-1/2 w-screen bg-ep-blue sm:w-fit	sm:left-0 sm:relative sm:flex-row sm:!h-auto sm:justify-end sm:shrink-0 sm:grow md:overflow-visible'
        }
        style={{ height: toggleMenu ? heightEl : '0px' }}
      >
        <Navitem
          class='hover:text-gray-400 px-2.5 py-1 rounded'
          link='/'
          toggleNav={toggleNav}
        >
          Home
        </Navitem>
        <Navitem
          class='hover:text-gray-400 px-2.5 py-1 rounded'
          link='/whitepaper/'
          toggleNav={toggleNav}
        >
          Whitepaper
        </Navitem>
        <Navitem
          class='hover:text-gray-400 px-2.5 py-1 rounded'
          submenu={['Elrond Pandas', 'Baby Pandas', 'Fallen Pandas']}
          subLink={['', 'baby-pandas/', 'fallen-pandas/']}
          resetMenu={resetMenuHeight}
          toggleNav={toggleNav}
        >
          Rarity
        </Navitem>
        {isLoggedIn ? (
          <>
            {' '}
            <Navitem
              class='ep-btn mb-3 sm:mb-0 hover:bg-slate-600'
              link='/dashboard/'
              toggleNav={toggleNav}
            >
              Dashboard
            </Navitem>
            <div className='sm:hidden ep-nav-menu flex flex-col w-full md:w-auto'>
              <a
                className='self-center ep-nav-item my-1 cursor-pointer w-full text-center sm:ml-5 sm:text-sm ep-btn mb-3 sm:mb-0 hover:bg-slate-600 whitespace-nowrap transition-all duration-100 sm:w-auto bg-red-900'
                onClick={handleLogout}
              >
                Logout
              </a>
            </div>
          </>
        ) : (
          <Navitem
            class='ep-btn mb-3 sm:mb-0 hover:bg-slate-600'
            link={routeNames.unlock}
            toggleNav={toggleNav}
          >
            Connexion
          </Navitem>
        )}
        {isLoggedIn && (
          <div className='hidden ep-nav-menu flex flex-col w-full md:w-auto lg:block'>
            <img
              className='w-5 inline-block sm:ml-5 mr-1'
              src={pandaCoin}
              alt='Panda coin'
              title={`${(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(
                0
              )} Panda Coins`}
            />
            <span
              className='self-center ep-nav-item my-1 w-full text-center  sm:text-sm mb-3 sm:mb-0 whitespace-nowrap transition-all duration-100 sm:w-auto  font-bold '
              title={`${(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(
                0
              )} Panda Coins`}
            >
              {(pdtValue ? pdtValue * Math.pow(10, -18) : 0).toFixed(0)}{' '}
            </span>
          </div>
        )}
      </ul>
      <img
        src={imgBurger}
        onClick={toggleNav}
        className='absolute right-1 top-4 w-10 cursor-pointer sm:hidden active:scale-110'
      />
    </nav>
  );
}
