import { dAppName } from 'config';
import BabyStaking from 'pages/admin/BabyStaking/BabyStaking';
import BoostShop from 'pages/admin/BoostShop/BoostShop';
import BoostShopLuck from 'pages/admin/BoostShop/BoostShopLuck';
import ClaimRewards from 'pages/admin/ClaimRewards/ClaimRewards';
import DashBoardPanda2 from 'pages/DashboardPanda/DashBoardPanda2';
import Mint from 'pages/DashboardPanda/Mint/Mint';
import Index from 'pages/Index/Index';
import Rarity from 'pages/Rarity/Rarity';
import UnlockPanda from 'pages/UnlockPanda/UnlockPanda';
import Whitepaper from 'pages/Whitepaper/Whitepaper';
import withPageTitle from './components/PageTitle';
import Transaction from './pages/Transaction';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  dashboardPage: '/dashboard/:page',
  dashboardPageSubpage: '/dashboard/:page/:subPage',
  transaction: '/transaction',
  unlock: '/unlock',
  unlock2: '/unlock2',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  whitepaper: '/whitepaper',
  whitepaperLang: '/whitepaper/:lang',
  whitepaperLangPage: '/whitepaper/:lang/:page',
  rarity: '/rarity',
  rarityCollection: '/rarity/:collection',
  rarityCollectionId: '/rarity/:collection/:id',
  BoostShop: '/BoostShop',
  BoostShopLuck: '/BoostShop2',
  ClaimRewards: '/ClaimRewards',
  BabyStaking: '/BabyStaking',
  Mint: '/Mint'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Index
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: DashBoardPanda2,
    authenticatedRoute: true
  },

  {
    path: routeNames.dashboardPage,
    title: 'Dashboard',
    component: DashBoardPanda2,
    authenticatedRoute: true
  },

  {
    path: routeNames.dashboardPageSubpage,
    title: 'Dashboard',
    component: DashBoardPanda2,
    authenticatedRoute: true
  },
  {
    path: routeNames.transaction,
    title: 'Transaction',
    component: Transaction
  },
  {
    path: routeNames.unlock,
    title: 'Unlock',
    component: UnlockPanda
  },
  {
    path: routeNames.whitepaper,
    title: 'Whitepaper',
    component: Whitepaper
  },
  {
    path: routeNames.whitepaperLang,
    title: 'Whitepaper',
    component: Whitepaper
  },
  {
    path: routeNames.whitepaperLangPage,
    title: 'Whitepaper',
    component: Whitepaper
  },
  {
    path: routeNames.rarity,
    title: 'Rarity',
    component: Rarity
  },
  {
    path: routeNames.rarityCollection,
    title: 'Rarity',
    component: Rarity
  },
  {
    path: routeNames.rarityCollectionId,
    title: 'Rarity',
    component: Rarity
  },
  {
    path: routeNames.BoostShop,
    title: 'BoostShop Time',
    component: BoostShop,
    authenticatedRoute: true
  },
  {
    path: routeNames.BoostShopLuck,
    title: 'BoostShop Luck',
    component: BoostShopLuck,
    authenticatedRoute: true
  },
  {
    path: routeNames.ClaimRewards,
    title: 'ClaimRewards',
    component: ClaimRewards,
    authenticatedRoute: true
  },
  {
    path: routeNames.BabyStaking,
    title: 'BabyStaking',
    component: BabyStaking,
    authenticatedRoute: true
  },
  {
    path: routeNames.Mint,
    title: 'Mint',
    component: Mint,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • Elrond ${dAppName}`
    : `Elrond ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
