import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import './TeamMember.css';

export default function TeamMember(props) {
  return (
    <div
      id={uuidv4()}
      className='ep-team-member mb-10 sm:flex sm:grid-cols-2 md:px-20 lg:mb-0 xl:px-48'
    >
      <div
        className={`sm:self-center sm:w-50vw sm:grow sm:shrink-0 sm:pr-5 ${
          props.inverse ? 'sm:order-1 sm:pl-10 md:text-left' : 'md:text-right'
        }`}
      >
        <h3 className='mb-1 sm:mb-5 text-4xl'>{props.name}</h3>
        <p
          className={`md:w-52 ${
            props.inverse ? '' : 'md:ml-auto md:my-0 md:mr-0'
          } xl:w-96`}
        >
          {props.children}
        </p>
      </div>
      <div>
        <img
          className='rounded-3xl border border-slate-500 md:w-52 z-10 relative'
          src={process.env.PUBLIC_URL + `/assets/img/members/${props.name}.png`}
          alt={`Picture of ${props.name}`}
        />
      </div>
    </div>
  );
}
