import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import {
  useGetAccountInfo,
  DappUI,
  logout,
  transactionServices,
  useGetPendingTransactions
} from '@elrondnetwork/dapp-core';
import { Address, AddressValue } from '@elrondnetwork/erdjs/out';
import { v4 as uuidv4 } from 'uuid';
import { querySc, sendTx } from 'components/TxTest/TxFunctions';
import {
  contractAddress,
  pandaCollection,
  fallenPandaCollection,
  claimReawardsAdress
} from 'config';
import './DashboardPanda.css';
import Card from 'components/Card/Card';
import { gateway, pandaToken, babyCollection } from 'config';
import { PandaContext } from 'context/PandaContext';
import pandaCoin from '../../assets/img/panda_coin.png';

export default function DashboardPanda() {
  const { address, account } = useGetAccountInfo();
  const [userPandas, setUserPandas] = useState() as any;
  const [pandaRarity, setPandaRarity] = useState() as any;
  const [userFallenPandas, setUserFallenPandas] = useState() as any;
  const [fallenPandaRarity, setFallenPandaRarity] = useState() as any;
  const [fallenNonceToPandas, setFallenNonceToPandas] = useState() as any;
  const [txId, setTxID] = useState(null) as any;
  const [refresh, setRefresh] = useState(0);
  const [userStakes, setUserStakes] = useState() as any;
  const [tokenToClaim, setTokenToClaim] = useState(0);
  const [userPdtTxs, setUserPdtTxs] = useState([]) as any;
  const [userBabyPandas, setUserBabyPandas] = useState() as any;
  const [babyPandaRarity, setBabyPandaRarity] = useState() as any;
  const [minCurrentClaimAmount, setMinCurrentClaimAmount] = useState() as any;
  const abiStakeUrl = '../../assets/json/nft_stake.abi.json';
  const { pendingTransactionsArray, hasPendingTransactions } =
    useGetPendingTransactions();
  const { setRefreshPdtValue, refreshPdtValue } = useContext(PandaContext);

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: txId,
    onSuccess: () => {
      setRefresh(refresh + 1);
      setRefreshPdtValue(refreshPdtValue + 1);
    },
    onFail: () => {
      console.log('fail');
    },
    onCancelled: () => {
      console.log('cancel');
    }
  });

  useEffect(() => {
    if (hasPendingTransactions) {
      setTxID(pendingTransactionsArray[0][0]);
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    console.log('refresh panda api');
    let apiErrorPanda = 0;
    //Elrond Pandas
    const userPandasLink =
      gateway +
      'accounts/' +
      address +
      '/nfts?size=10000&collections=' +
      pandaCollection;
    const fetchUserPanda = () => {
      fetch(userPandasLink)
        .then((response) => {
          const userPandasJson = response.json();
          return userPandasJson;
        })
        .then((userPandasJson) => {
          console.log(userPandasLink);

          setUserPandas(userPandasJson);
        })
        .catch(function (error) {
          apiErrorPanda++;
          if (apiErrorPanda < 3) {
            fetchUserPanda();
          } else {
            console.log(
              // eslint-disable-next-line quotes
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          }
        });
    };
    fetchUserPanda();

    fetch('/collection/ElrondPandas/json/rank.json')
      .then((response) => {
        const rank_data: any = response.json();
        return rank_data;
      })
      .then((rank_data) => {
        const rank_data_sorted: any = Object.entries(rank_data)
          .sort(([, a]: any, [, b]: any) => a - b)
          .reverse();

        rank_data_sorted.map(
          (item: { rank: any; visible: boolean }, key: number) => {
            item.rank = key + 1;
            item.visible = true;
          }
        );
        setPandaRarity(rank_data_sorted);
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message + `/collection/${collectionLink}/json/rank.json`);
      });

    //Fallen Pandas
    let apiErrorFp = 0;
    const userFallenPandasLink =
      gateway +
      'accounts/' +
      address +
      '/nfts?size=10000&collections=' +
      fallenPandaCollection;
    const fetchUserFp = () => {
      fetch(userFallenPandasLink)
        .then((response) => {
          const userFallenPandasJson = response.json();
          return userFallenPandasJson;
        })
        .then((userFallenPandasJson) => {
          // console.log(userFallenPandasJson);

          setUserFallenPandas(userFallenPandasJson);
        })
        .catch(function (error) {
          apiErrorFp++;
          if (apiErrorFp < 3) {
            fetchUserFp();
          } else {
            console.log(
              // eslint-disable-next-line quotes
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          }
        });
    };
    fetchUserFp();

    fetch('/collection/FallenPandas/json/rank.json')
      .then((response) => {
        const rank_data: any = response.json();
        return rank_data;
      })
      .then((rank_data) => {
        const rank_data_sorted: any = Object.entries(rank_data)
          .sort(([, a]: any, [, b]: any) => a - b)
          .reverse();

        rank_data_sorted.map(
          (item: { rank: any; visible: boolean }, key: number) => {
            item.rank = key + 1;
            item.visible = true;
          }
        );
        console.log(rank_data_sorted);
        setFallenPandaRarity(rank_data_sorted);
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message + `/collection/${collectionLink}/json/rank.json`);
      });

    fetch('/assets/json/fallenNonceToName.json')
      .then((response) => {
        const fallenNTN: any = response.json();
        return fallenNTN;
      })
      .then((fallenNTN) => {
        setFallenNonceToPandas(fallenNTN);
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message + `/collection/${collectionLink}/json/rank.json`);
      });

    //Baby Pandas
    let apiErrorBp = 0;
    const babyPandaCollectionLink =
      gateway +
      'accounts/' +
      address +
      '/nfts?size=10000&collections=' +
      babyCollection;
    const fetchBabyPanda = () => {
      fetch(babyPandaCollectionLink)
        .then((response) => {
          const useBabyPandasJson = response.json();
          return useBabyPandasJson;
        })
        .then((useBabyPandasJson) => {
          setUserBabyPandas(useBabyPandasJson);
        })
        .catch(function (error) {
          apiErrorBp++;
          if (apiErrorBp < 3) {
            fetchBabyPanda();
          } else {
            console.log(
              // eslint-disable-next-line quotes
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          }
        });
    };
    fetchBabyPanda();

    fetch('/collection/BabyPandas/json/rank.json')
      .then((response) => {
        const rank_data: any = response.json();
        return rank_data;
      })
      .then((rank_data) => {
        const rank_data_sorted: any = Object.entries(rank_data)
          .sort(([, a]: any, [, b]: any) => a - b)
          .reverse();

        rank_data_sorted.map(
          (item: { rank: any; visible: boolean }, key: number) => {
            item.rank = key + 1;
            item.visible = true;
          }
        );
        setBabyPandaRarity(rank_data_sorted);
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message + `/collection/${collectionLink}/json/rank.json`);
      });
  }, [refresh]);

  /*** SC claim ***/
  useEffect(() => {
    console.log('refresh User stack');

    //Quelles stacks a l'user
    const getStacks = async () => {
      const queryGetStacks: any = await querySc(
        claimReawardsAdress,
        'getAllStakes',
        abiStakeUrl,
        [new AddressValue(new Address(address))]
      );
      setUserStakes(queryGetStacks);
      console.log(queryGetStacks);
    };
    getStacks();
  }, [refresh]);

  useEffect(() => {
    //Get min_claim_amount
    const fetchMinClaimAmount = async () => {
      const queryMinClaimAmount: any = await querySc(
        claimReawardsAdress,
        'min_claim_amount',
        abiStakeUrl
      );
      setMinCurrentClaimAmount(queryMinClaimAmount.toNumber());
      console.log(queryMinClaimAmount.toNumber());
    };
    fetchMinClaimAmount();
  }, []);

  //Get component txId
  const getTxId = (tx: any) => {
    setTxID(tx);
  };

  //Get All rewards
  useEffect(() => {
    const getRewards = async () => {
      const queryGetRewards: any = await querySc(
        claimReawardsAdress,
        'getAllRewards',
        abiStakeUrl,
        [new AddressValue(new Address(address))]
      );
      if (typeof queryGetRewards != 'string') {
        setTokenToClaim(queryGetRewards.toNumber());
      }
    };
    getRewards();
    const rewardsInterval = setInterval(() => {
      //Get rewards every 10s
      getRewards();
    }, 10000);

    return () => {
      clearInterval(rewardsInterval);
    };
  }, [refresh]);

  useEffect(() => {
    // console.log(userPdtTxs);
  }, [userPdtTxs]);

  //Get all user panda token transctions
  useEffect(() => {
    const userTokenApi =
      gateway + 'accounts/' + address + '/results?size=10000';
    let apiErrorPt = 0;

    const fetchPandaToken = () => {
      fetch(userTokenApi)
        .then((response) => {
          const userTokenResponse = response.json();
          return userTokenResponse;
        })
        .then((userTokenResponse) => {
          console.log(userTokenResponse);
          const txArray: { txHash: any; txTimestamps: any; txValue: any }[] =
            [];
          const maxTxToDisplay = 10;
          let currentTx = 0;
          userTokenResponse.map((tx: any, index: any) => {
            if (currentTx === maxTxToDisplay) return false;
            if (
              typeof tx.action !== 'undefined' &&
              tx.action.category == 'esdtNft'
            ) {
              tx.action.arguments.transfers.map((transfer: any) => {
                if (transfer.ticker == pandaToken) {
                  currentTx++;
                  txArray.push({
                    txHash: tx.hash,
                    txTimestamps: tx.timestamp,
                    txValue: transfer.value
                  });
                }
              });
            }
          });
          setUserPdtTxs(txArray);
        })
        .catch(function (error) {
          apiErrorPt++;
          if (apiErrorPt < 3) {
            fetchPandaToken();
          } else {
            console.log(
              // eslint-disable-next-line quotes
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          }
        });
    };
    fetchPandaToken();
  }, [refresh]);

  //Function that return the date from a timestamp
  const getDate = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    const dateString = date.toLocaleDateString();
    return dateString;
  };

  return (
    <div className='relative grid md:grid-cols-2 z-10'>
      <div className='md:pl-7'>
        <h2 className=' !font-semibold !text-4xl !mb-3 !normal-case'>
          Your Pandas
        </h2>
        {userPandas &&
        userStakes &&
        (userPandas.length ||
          userStakes.filter(
            (e: any) =>
              String.fromCharCode.apply(null, e.collection_id) ===
              pandaCollection
          ).length) ? (
          <div className='max-w-7xl m-auto grid grid-cols-2 gap-3 sm:gap-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
            {userStakes
              .filter(
                (e: any) =>
                  String.fromCharCode.apply(null, e.collection_id) ===
                  pandaCollection
              )
              .map((panda: any) => {
                // console.log(panda.name);

                // const isGoodCollection =
                //   String.fromCharCode.apply(null, panda.collection_id) ===
                //   pandaCollection;

                return (
                  <Card
                    key={uuidv4()}
                    id={panda.nonce.toNumber()}
                    link={'ElrondPandas'}
                    nftName={'Elrond Panda'}
                    collection={'ElrondPandas'}
                    extension='png'
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    // rank={
                    //   pandaRarity &&
                    //   pandaRarity.find(
                    //     (item: any) =>
                    //       item[0] === panda.nonce.toNumber().toString(16)
                    //   )['rank']
                    // }
                    stakeInfo='true'
                    staked='true'
                    panda={{
                      nonce: panda.nonce.toNumber(),
                      identifier: String.fromCharCode.apply(
                        null,
                        panda.collection_id
                      )
                    }}
                    sendTxId={getTxId}
                  />
                );
              })}
            {userPandas.map((panda: any) => {
              // console.log(panda);
              return (
                <Card
                  key={uuidv4()}
                  id={panda.name.replace('Panda #', '')}
                  link={'ElrondPandas'}
                  nftName={'Elrond Panda'}
                  collection={'ElrondPandas'}
                  extension='png'
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  // rank={
                  //   pandaRarity &&
                  //   pandaRarity.find(
                  //     (item: any) =>
                  //       item[0] === panda.name.replace('EPANDAS #', '')
                  //   )['rank']
                  // }
                  stakeInfo='true'
                  staked='false'
                  panda={panda}
                  sendTxId={getTxId}
                />
              );
            })}
          </div>
        ) : (
          <p>You don&apos;t have any Elrond Panda in your wallet</p>
        )}

        <h2 className='!font-semibold !text-4xl !mb-3 !normal-case'>
          Your Baby Pandas
        </h2>
        {userBabyPandas &&
        userStakes &&
        (userBabyPandas.length ||
          userStakes.filter(
            (e: any) =>
              String.fromCharCode.apply(null, e.collection_id) ===
              babyCollection
          ).length) ? (
          <div className='max-w-7xl m-auto grid grid-cols-2 gap-3 sm:gap-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
            {userStakes
              .filter(
                (e: any) =>
                  String.fromCharCode.apply(null, e.collection_id) ===
                  babyCollection
              )
              .map((panda: any) => {
                return (
                  <Card
                    key={uuidv4()}
                    id={panda.nonce.toNumber()}
                    link={'BabyPandas'}
                    nftName={'Baby Panda'}
                    collection={'baby-pandas'}
                    extension='png'
                    // rank={
                    //   babyPandaRarity &&
                    //   babyPandaRarity.find(
                    //     (item: any) => item[0] === panda.nonce.toNumber()
                    //   )['rank']
                    // }
                    // rank={panda.nonce.toNumber().toString(16)}
                    stakeInfo='true'
                    staked='true'
                    panda={{
                      nonce: panda.nonce.toNumber(),
                      identifier: String.fromCharCode.apply(
                        null,
                        panda.collection_id
                      )
                    }}
                    sendTxId={getTxId}
                  />
                );
              })}
            {userBabyPandas.map((panda: any) => {
              // console.log(panda.name);
              return (
                <Card
                  key={uuidv4()}
                  id={panda.name.replace('Baby Panda #', '')}
                  link={'BabyPandas'}
                  nftName={'Baby Panda'}
                  collection={'baby-pandas'}
                  extension='png'
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  // rank={
                  //   babyPandaRarity &&
                  //   babyPandaRarity.find(
                  //     (item: any) =>
                  //       item[0] === panda.name.replace('Baby Panda #', '')
                  //   )['rank']
                  // }
                  stakeInfo='true'
                  staked='false'
                  panda={panda}
                  sendTxId={getTxId}
                />
              );
            })}
          </div>
        ) : (
          <p className=''>You don&apos;t have any Baby Panda in your wallet</p>
        )}

        <h2 className='!font-semibold !text-4xl !mb-3 !normal-case'>
          Your Fallen Pandas
        </h2>
        {userFallenPandas &&
        userStakes &&
        fallenNonceToPandas &&
        (userFallenPandas.length ||
          userStakes.filter(
            (e: any) =>
              String.fromCharCode.apply(null, e.collection_id) ===
              fallenPandaCollection
          ).length) ? (
          <div className='max-w-7xl m-auto grid grid-cols-2 gap-3 sm:gap-10 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
            {userStakes
              .filter(
                (e: any) =>
                  String.fromCharCode.apply(null, e.collection_id) ===
                  fallenPandaCollection
              )
              .map((panda: any) => {
                return (
                  <Card
                    key={uuidv4()}
                    id={fallenNonceToPandas[panda.nonce.toNumber()].replace(
                      'Pandas Fallen #',
                      ''
                    )}
                    link={'FallenPandas'}
                    nftName={'Fallen Panda'}
                    collection={'fallen-pandas'}
                    extension='jpg'
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    // rank={
                    //   fallenPandaRarity &&
                    //   fallenPandaRarity.find(
                    //     (item: any) =>
                    //       item[0] === panda.nonce.toNumber().toString(16)
                    //   )['rank']
                    // }
                    stakeInfo='true'
                    staked='true'
                    panda={{
                      nonce: panda.nonce.toNumber(),
                      identifier: String.fromCharCode.apply(
                        null,
                        panda.collection_id
                      )
                    }}
                    sendTxId={getTxId}
                  />
                );
              })}
            {userFallenPandas.map((panda: any) => {
              // console.log(panda.name);
              return (
                <Card
                  key={uuidv4()}
                  id={panda.name.replace('Pandas Fallen #', '')}
                  link={'FallenPandas'}
                  nftName={'Fallen Panda'}
                  collection={'fallen-pandas'}
                  extension='jpg'
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  // rank={
                  //   fallenPandaRarity &&
                  //   fallenPandaRarity.find(
                  //     (item: any) =>
                  //       item[0] === panda.name.replace('Pandas Fallens #', '')
                  //   )['rank']
                  // }
                  stakeInfo='true'
                  staked='false'
                  panda={panda}
                  sendTxId={getTxId}
                />
              );
            })}
          </div>
        ) : (
          <p>You don&apos;t have any Fallen Panda in your wallet</p>
        )}
      </div>
      <div className='md:ml-10 max-w-xl'>
        <div className='border-b border-ep-grey1 flex flex-col md:flex-row md:items-end mb-3'>
          <h2 className=' !font-semibold !text-4xl !mb-2 md:!mb-0 !normal-case '>
            PandaToken rewards
          </h2>
          <div className='ml-10'>
            <button
              className={`ep-btn !text-md mb-1 px-7 ${
                tokenToClaim >= minCurrentClaimAmount
                  ? 'bg-ep-green hover:bg-ep-green2 cursor-pointer'
                  : '!bg-gray-600 cursor-not-allowed'
              }`}
              onClick={async () => {
                if (tokenToClaim >= minCurrentClaimAmount) {
                  const claimRewards: any = await sendTx(
                    claimReawardsAdress,
                    'claimAll',
                    [],
                    0,
                    abiStakeUrl
                  );
                  setTxID(claimRewards.sessionId);
                }
              }}
            >
              Claim&nbsp;
              {(tokenToClaim * Math.pow(10, -18)).toFixed(2)}
              &nbsp;
              {tokenToClaim > 0 && 'token'}
              {tokenToClaim * Math.pow(10, -18) > 1 && 's'}
            </button>
          </div>
        </div>
        <div>
          {userPdtTxs.length ? (
            <table
              className='w-full text-center border-separate'
              style={{ borderSpacing: '0 5px' }}
            >
              {userPdtTxs.map((tx: any, id: number) => {
                return (
                  <tr key={id}>
                    <td className='w-11'>
                      <img
                        className='h-11 w-11 m-auto'
                        src={pandaCoin}
                        alt='Panda token'
                      />
                    </td>
                    <td className='hidden sm:block'>
                      <span className='block leading-none	'>Pandatoken</span>
                      <span className='text-ep-grey1 block leading-none	mt-1'>
                        Token rewarded
                      </span>
                    </td>
                    <td>
                      <span className='block leading-none'>
                        {getDate(tx.txTimestamps)}
                      </span>
                      <span className='text-ep-grey1 block leading-none	mt-1'>
                        Distribution date
                      </span>
                    </td>
                    <td>
                      <b>{(tx.txValue * Math.pow(10, -18)).toFixed(2)} PDT</b>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <p>
              You don&apos;t have any reward yet. <b>Stake</b> a panda to earn
              Panda tokens
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
