export const contractAddress = '';

export const boostShopAdress =
  'erd1qqqqqqqqqqqqqpgqw3ay86jtyuevpph8pzww734xljrpklz03vgqhnf7f2';

export const boostShopLuckAdress =
  'erd1qqqqqqqqqqqqqpgq384mrwnd9ye56dxnqdddny3fqmr9pn553vgqv2a96j';

export const claimReawardsAdress =
  'erd1qqqqqqqqqqqqqpgqetl80j7tffku8w2ysv0cnvdg50sn52xd3vgqmxjdut';

export const mintAddress = '';

export const babyStakingAddress =
  'erd1qqqqqqqqqqqqqpgqzx7xtnjngrzmpzskjduxfu0lk45swfsh68mq6cayds';

export const dAppName = 'Elrond Pandas';

export const gateway = 'https://api.elrond.com/';
export const gatewayUnslashed = 'https://api.elrond.com';

export const pandaCollection = 'PANDAS-e06423';
export const pandaCollection2 = 'PANDAS-e06423'; //Same collec in mainnet
export const fallenPandaCollection = 'EPFALLEN-916366';
export const babyCollection = 'BABYPANDAS-a1969a';
export const pandaToken = 'PTOKEN-956ddd';
export const pandaToken2 = 'PTOKEN-956ddd';

const partnerCollectionAPC = 'APC-928458';

export const boostShopSft = 'PBT-3c57db';
export const boostShopSft15 = 'PBT-3c57db-01';
export const boostShopSft30 = 'PBT-3c57db-02';
export const boostShopSft40 = 'PBT-3c57db-03';
export const boostShopSft50 = 'PBT-3c57db-04';

export const boostShopLuckSft = 'PBP-19a67f';
export const boostShopLuckSft15 = 'PBP-19a67f-01';
export const boostShopLuckSft30 = 'PBP-19a67f-02';
export const boostShopLuckSft40 = 'PBP-19a67f-03';
export const boostShopLuckSft50 = 'PBP-19a67f-04';
