import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './FaqItem.css';

export default function FaqItem(props) {
  const [toggleQuestion, SetToggleQuestion] = useState(false);
  const [itemId, SetItemId] = useState(uuidv4()); /*L'id de cet item*/

  const [idReceived, SetIdReceived] = useState();

  const [heightEl, setHeightEl] = useState();
  const refItemHeight = useRef();

  useEffect(() => {
    //Donne la hauteur du texte
    setHeightEl(`${refItemHeight.current.scrollHeight}px`);

    SetIdReceived(props.giveItemId);
  });

  useEffect(() => {
    if (idReceived != itemId) {
      SetToggleQuestion(false);
    }
  }, [idReceived]);

  const toggleQuestionState = () => {
    setTimeout(() => SetToggleQuestion(!toggleQuestion), 200);
  };

  return (
    <div
      onClick={() => props.getFaqItemId(itemId)}
      className='ep-faq-item mb-8 active'
    >
      <div
        onClick={toggleQuestionState}
        className={'uppercase font-extrabold text-3xl cursor-pointer'}
      >
        {props.question}
      </div>
      <div
        style={{ height: toggleQuestion ? heightEl : '0px' }}
        className='overflow-hidden transition-all duration-200 mt-1'
        ref={refItemHeight}
      >
        {props.children}
      </div>
    </div>
  );
}
