import React, { createContext, useEffect, useState } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { gateway, pandaToken } from 'config';
import { getUserBoosts } from './ContextFunctions';

export type userBoosts = {
  boostTime15: number;
  boostTime30: number;
  boostTime40: number;
  boostTime50: number;
  boostLuck15: number;
  boostLuck30: number;
  boostLuck40: number;
  boostLuck50: number;
};

interface PandaContextInterface {
  pdtValue: number;
  setPdtValue: (value: number) => void;
  refreshPdtValue: number;
  setRefreshPdtValue: (value: number) => void;
  pandasMinted: Array<number>;
  setPandasMinted: (value: Array<number>) => void;
  userBoosts: userBoosts;
  setUserBoosts: (value: userBoosts) => void;
  refreshUserBoosts: number;
  setRefreshUserBoosts: (value: number) => void;
}

export const PandaContext = createContext<PandaContextInterface>({
  pdtValue: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPdtValue: (value: number) => {},
  refreshPdtValue: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefreshPdtValue: (value: number) => {},
  pandasMinted: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPandasMinted: (value: Array<number>) => {},
  userBoosts: {
    boostTime15: 0,
    boostTime30: 0,
    boostTime40: 0,
    boostTime50: 0,
    boostLuck15: 0,
    boostLuck30: 0,
    boostLuck40: 0,
    boostLuck50: 0
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserBoosts: (value: userBoosts) => {},
  refreshUserBoosts: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefreshUserBoosts: (value: number) => {}
});

const PandaContaxtProvider = (props: any) => {
  const [pdtValue, setPdtValue] = useState(0);
  const [refreshPdtValue, setRefreshPdtValue] = useState(0);
  const { address } = useGetAccountInfo();
  const [pandasMinted, setPandasMinted] = useState<Array<number>>([]);
  const [userBoosts, setUserBoosts] = useState<userBoosts>({
    boostTime15: 0,
    boostTime30: 0,
    boostTime40: 0,
    boostTime50: 0,
    boostLuck15: 0,
    boostLuck30: 0,
    boostLuck40: 0,
    boostLuck50: 0
  });
  const [refreshUserBoosts, setRefreshUserBoosts] = useState(0);

  useEffect(() => {
    if (address) {
      let apiError = 0;
      const userPdtTokenApi =
        gateway + 'accounts/' + address + '/tokens/' + pandaToken;
      const fetchUserPtdToken = () => {
        fetch(userPdtTokenApi)
          .then((response) => {
            const userPdtTokenJson = response.json();
            return userPdtTokenJson;
          })
          .then((userPdtTokenJson) => {
            setPdtValue(userPdtTokenJson.balance);
          })
          .catch(function (error) {
            apiError++;
            if (apiError < 3) {
              fetchUserPtdToken();
            } else {
              console.log(
                // eslint-disable-next-line quotes
                "Il y a eu un problème avec l'opération fetch: " + error.message
              );
            }
          });
      };
      fetchUserPtdToken();
    }
  }, [refreshPdtValue]);

  useEffect(() => {
    let apiError = 0;
    //Get user boosts
    const getUserBoostsFn = () => {
      getUserBoosts(address)
        .then((userBoostsValue) => {
          // console.log('userBoostsValue', userBoostsValue);
          setUserBoosts(userBoostsValue);
        })
        .catch(function (error) {
          apiError++;
          if (apiError < 3) {
            getUserBoostsFn();
          } else {
            console.log(
              // eslint-disable-next-line quotes
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          }
        });
    };
    getUserBoostsFn();
  }, [refreshUserBoosts]);

  return (
    <PandaContext.Provider
      value={{
        pdtValue,
        setPdtValue,
        refreshPdtValue,
        setRefreshPdtValue,
        pandasMinted,
        setPandasMinted,
        userBoosts,
        setUserBoosts,
        refreshUserBoosts,
        setRefreshUserBoosts
      }}
    >
      {props.children}
    </PandaContext.Provider>
  );
};

export default PandaContaxtProvider;
