import './index.css';
import './tailwind.css';
import './wpCore.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './assets/sass/theme.scss';

ReactDOM.render(<App />, document.getElementById('root'));
