import React from 'react';
import logo from '../../../assets/img/logo-full.png';
import bambou from './Bamboo.png';
import './NewsItem.css';

export default function NewsItem(props: any) {
  return (
    <div className='relative ep-news-item  border-8 rounded-3xl border-ep-grey3 overflow-hidden bg-ep-blue z-10 mb-5'>
      <div className='flex mx-5 mt-2'>
        <h1 className='flex-1 underline font-bold  text-3xl md:text-4xl'>
          {props.title}
        </h1>
        <div className='text-xl'>{props.date}</div>
      </div>
      <div className='flex'>
        {/* <div className='relative w-16 ml-2 mt-2'>
          <img
            className='ep-news-item-bamboo absolute'
            src={bambou}
            alt='Bamboo'
          />
        </div> */}
        <div
          className='flex-1 p-5'
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
        <div className='self-end p-5 hidden md:block'>
          <img className='w-28 ' src={logo} alt='Logo' />
        </div>
      </div>
    </div>
  );
}
